import React from "react";
import ContactezNous from "../../ContacterNous"; // Ensure the component is imported correctly

function IdeePrecise() {
  return (
    <div className="pb-10">
      <div className="flex items-center justify-center mb-10 flex-row space-y-0 space-x-4">
        <div className="h-1 w-32 md:w-52 bg-white"></div>
        <div className="px-4">
          <div className="text-center text-white MohaveBold text-2xl md:text-3xl lg:text-5xl">
            Vous avez une idée précise ?
          </div>
        </div>
        <div className="h-1 w-32 md:w-52 bg-white"></div>
      </div>

      <ContactezNous />
    </div>
  );
}

export default IdeePrecise;
