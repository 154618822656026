import React, { useState } from "react";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import HomeScreen from "./screen/HomeScreen";
import Event from "./screen/EvenementScreen";
import Diy from "./screen/ActiviteScreen";
import EventDetailScreen from "./screen/EventDetailScreen";
import "./App.css";
import MariageScreen from "./screen/MariageScreen";
import ContactScreen from "./screen/ContactScreen";
import Footer from "./component/Footer";
import MentionsLegales from "./screen/MentionLegales";
import Navbar from "./component/Navbar";
import LocationDetailScreen from "./screen/LocationDetailScreen";
import ActiviteScreen from "./screen/ActiviteScreen";
import LoginPage from "./screen/LoginPage";
import AdminScreen from "./screen/AdminScreen";
import AddEvents from "./component/admin/AddEvent";
import AddSectionEvent from "./component/admin/AddSectionEvent";
import AddActivity from "./component/admin/AddActivity";
import AddSectionActivity from "./component/admin/AddSectionActivity";
import AddLocation from "./component/admin/AddLocation";
import AddSectionLocation from "./component/admin/AddSectionLocation";
import EditEvent from "./component/admin/EditEvent";
import EditSectionEvent from "./component/admin/EditSectionEvent";
import EditActivity from "./component/admin/EditActivity";
import EditLocation from "./component/admin/EditLocation";
import EditSectionActivity from "./component/admin/EditSectionActivity";
import EditSectionLocation from "./component/admin/EditSectionLocation";
import DeleteEvent from "./component/admin/DeleteEvent";
import DeleteActivity from "./component/admin/DeleteActivity";
import DeleteLocation from "./component/admin/DeleteLocation";
import DeleteSectionEvent from "./component/admin/DeleteSectionEvent";
import DeleteSectionActivity from "./component/admin/DeleteSectionActivity";
import DeleteSectionLocation from "./component/admin/DeleteSectionLocation";
import EditContact from "./component/admin/EditContact";
import ProtectedRoute from "./component/ProtectedRoute";
import EditMariage from "./component/admin/EditMariage";
import EditSectionMariage from "./component/admin/EditSectionMariage";
import AllLocation from "./screen/AllLocation";
import AllActivite from "./screen/AllActivite";
import EditMaryline from "./component/admin/EditMaryline";

function App() {
  const [loggedIn, setLoggedIn] = useState(false);

  const handleLogin = () => {
    setLoggedIn(true);
  };

  return (
    <Router>
      <div className="flex flex-col min-h-screen">
        <Navbar />
        <div className="flex-grow">
          <Routes>
            <Route path="/" element={<HomeScreen />} />
            <Route path="/evenement" element={<Event />} />
            <Route path="/activite/:name" element={<ActiviteScreen />} />
            <Route path="/activite" element={<AllActivite />} />
            <Route path="/event/:name" element={<EventDetailScreen />} />
            <Route path="/mariage" element={<MariageScreen />} />
            <Route path="/contact" element={<ContactScreen />} />
            <Route path="/location/:name" element={<LocationDetailScreen />} />
            <Route path="/location" element={<AllLocation />} />

            <Route path="/mentions-legales" element={<MentionsLegales />} />
            <Route
              path="/login"
              element={<LoginPage onLogin={handleLogin} />}
            />

            {/* Routes protégées */}
            <Route
              path="/admin"
              element={
                <ProtectedRoute>
                  <AdminScreen />
                </ProtectedRoute>
              }
            />
            <Route
              path="/admin/add/event"
              element={
                <ProtectedRoute>
                  <AddEvents />
                </ProtectedRoute>
              }
            />
            <Route
              path="/admin/add/activity"
              element={
                <ProtectedRoute>
                  <AddActivity />
                </ProtectedRoute>
              }
            />
            <Route
              path="/admin/add/location"
              element={
                <ProtectedRoute>
                  <AddLocation />
                </ProtectedRoute>
              }
            />
            <Route
              path="/admin/add/locationSection"
              element={
                <ProtectedRoute>
                  <AddSectionLocation />
                </ProtectedRoute>
              }
            />
            <Route
              path="/admin/add/activitySection"
              element={
                <ProtectedRoute>
                  <AddSectionActivity />
                </ProtectedRoute>
              }
            />
            <Route
              path="/admin/add/eventSection"
              element={
                <ProtectedRoute>
                  <AddSectionEvent />
                </ProtectedRoute>
              }
            />
            <Route
              path="/admin/edit/event"
              element={
                <ProtectedRoute>
                  <EditEvent />
                </ProtectedRoute>
              }
            />
            <Route
              path="/admin/edit/maryline"
              element={
                <ProtectedRoute>
                  <EditMaryline />
                </ProtectedRoute>
              }
            />
            <Route
              path="/admin/edit/mariage"
              element={
                <ProtectedRoute>
                  <EditMariage />
                </ProtectedRoute>
              }
            />
            <Route
              path="/admin/edit/mariageSection"
              element={
                <ProtectedRoute>
                  <EditSectionMariage />
                </ProtectedRoute>
              }
            />
            <Route
              path="/admin/edit/activity"
              element={
                <ProtectedRoute>
                  <EditActivity />
                </ProtectedRoute>
              }
            />
            <Route
              path="/admin/edit/location"
              element={
                <ProtectedRoute>
                  <EditLocation />
                </ProtectedRoute>
              }
            />
            <Route
              path="/admin/edit/contact"
              element={
                <ProtectedRoute>
                  <EditContact />
                </ProtectedRoute>
              }
            />
            <Route
              path="/admin/edit/eventSection"
              element={
                <ProtectedRoute>
                  <EditSectionEvent />
                </ProtectedRoute>
              }
            />
            <Route
              path="/admin/edit/activitySection"
              element={
                <ProtectedRoute>
                  <EditSectionActivity />
                </ProtectedRoute>
              }
            />
            <Route
              path="/admin/edit/locationSection"
              element={
                <ProtectedRoute>
                  <EditSectionLocation />
                </ProtectedRoute>
              }
            />
            <Route
              path="/admin/delete/event"
              element={
                <ProtectedRoute>
                  <DeleteEvent />
                </ProtectedRoute>
              }
            />
            <Route
              path="/admin/delete/activity"
              element={
                <ProtectedRoute>
                  <DeleteActivity />
                </ProtectedRoute>
              }
            />
            <Route
              path="/admin/delete/location"
              element={
                <ProtectedRoute>
                  <DeleteLocation />
                </ProtectedRoute>
              }
            />
            <Route
              path="/admin/delete/eventSection"
              element={
                <ProtectedRoute>
                  <DeleteSectionEvent />
                </ProtectedRoute>
              }
            />
            <Route
              path="/admin/delete/activitySection"
              element={
                <ProtectedRoute>
                  <DeleteSectionActivity />
                </ProtectedRoute>
              }
            />
            <Route
              path="/admin/delete/locationSection"
              element={
                <ProtectedRoute>
                  <DeleteSectionLocation />
                </ProtectedRoute>
              }
            />
          </Routes>
        </div>
        <Footer />
      </div>
    </Router>
  );
}

export default App;
