import React, { useState } from "react";
import { Navigate } from "react-router-dom";
import { FaEye, FaEyeSlash } from "react-icons/fa";
import axiosInstance from "../middleware/axiosInstance";

function LoginPage({ onLogin }) {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [error, setError] = useState(null);
  const [isLoggedIn, setIsLoggedIn] = useState(false);
  const [showPassword, setShowPassword] = useState(false);

  const handleSubmit = (e) => {
    e.preventDefault();
    const userData = {
      email: email,
      password: password,
    };
    axiosInstance
      .post("/users/login", userData)
      .then((response) => {
        document.cookie = `authorization=${response.data.token}; path=/; SameSite=None; Secure`;
        setIsLoggedIn(true);
        onLogin();
      })
      .catch((error) => {
        console.error(error);
        setError("Adresse email ou mot de passe incorrect.");
      });
  };

  if (isLoggedIn) {
    return <Navigate to="/admin" />;
  }

  return (
    <section className="h-screen bg-gradient-to-br from-white to-black flex items-center justify-center mt-10">
      <div className="w-full max-w-md p-6 bg-white shadow-md rounded-lg">
        <div className="flex flex-col items-center">
          <img
            className="w-64  rounded-full mb-6"
            src={`${process.env.PUBLIC_URL}/LogoNoir.svg`}
            alt="Logo"
          />
          <h1 className="text-2xl font-bold mb-6">Se connecter</h1>
          <form onSubmit={handleSubmit} className="w-full">
            <div className="mb-4">
              <label className="block text-gray-700 mb-2" htmlFor="email">
                Adresse email
              </label>
              <input
                type="email"
                id="email"
                className="w-full p-3 border rounded-lg focus:outline-none focus:ring-2 focus:ring-blue-500"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
              />
            </div>
            <div className="mb-6">
              <label className="block text-gray-700 mb-2" htmlFor="password">
                Mot de passe
              </label>
              <div className="relative">
                <input
                  type={showPassword ? "text" : "password"}
                  id="password"
                  className="w-full p-3 border rounded-lg focus:outline-none focus:ring-2 focus:ring-blue-500"
                  value={password}
                  onChange={(e) => setPassword(e.target.value)}
                />
                <span
                  className="absolute top-1/3 right-2 cursor-pointer text-gray-500"
                  onClick={() => setShowPassword(!showPassword)}
                >
                  {showPassword ? <FaEyeSlash /> : <FaEye />}
                </span>
              </div>
            </div>
            <button
              type="submit"
              className="w-full py-3 bg-green text-white rounded-lg hover:bg-black focus:outline-none focus:ring-2 focus:ring-blue-500"
            >
              Se connecter
            </button>
            {error && <div className="mt-4 text-red-500">{error}</div>}
            <div className="mt-4 text-center"></div>
          </form>
        </div>
      </div>
    </section>
  );
}

export default LoginPage;
