import React, { useState, useEffect } from "react";
import axiosInstance from "../../middleware/axiosInstance";

const EditMariage = () => {
  const [mariages, setMariages] = useState([]);
  const [selectedMariageId, setSelectedMariageId] = useState("");
  const [mariageData, setMariageData] = useState(null);
  const [name, setName] = useState("");
  const [imgBandeau, setImgBandeau] = useState(null);
  const [imageUrlBandeau, setImageUrlBandeau] = useState("");

  // Fetch all mariages on component mount
  useEffect(() => {
    axiosInstance
      .get("/mariage")
      .then((response) => {
        setMariages(response.data);
      })
      .catch((error) => {
        console.error("Error fetching mariages:", error);
      });
  }, []);

  // Fetch selected mariage data when selectedMariageId changes
  useEffect(() => {
    if (selectedMariageId) {
      axiosInstance
        .get(`/mariage/${selectedMariageId}`)
        .then((response) => {
          const mariageData = response.data;
          setMariageData(mariageData);
          setName(mariageData.name);
          setImageUrlBandeau(mariageData.img_bandeau);
        })
        .catch((error) => {
          console.error("Error fetching mariage:", error);
        });
    }
  }, [selectedMariageId]);

  const handleFileChangeBandeau = (event) => {
    const file = event.target.files[0];
    setImgBandeau(file);
    setImageUrlBandeau(file.name);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    const uploadFile = async (file) => {
      const formData = new FormData();
      formData.append("file", file);

      const config = {
        headers: { "content-type": "multipart/form-data" },
      };

      try {
        const response = await axiosInstance.post("/upload", formData, config);
        return response.data.filePath;
      } catch (error) {
        console.error("There was an error uploading the file!", error);
        throw error;
      }
    };

    let uploadedImageUrlBandeau = imageUrlBandeau;

    if (imgBandeau) {
      uploadedImageUrlBandeau = await uploadFile(imgBandeau);
    }

    try {
      // Prepare mariage data
      const updatedMariageData = {
        name,
        img_bandeau: uploadedImageUrlBandeau,
      };

      // Send mariage data to the server
      await axiosInstance.put(
        `/mariage/${selectedMariageId}`,
        updatedMariageData
      );
      alert("Mariage updated successfully!");
    } catch (error) {
      console.error("There was an error updating the mariage!", error);
    }
  };

  return (
    <div className="mt-32 flex justify-center">
      <form
        onSubmit={handleSubmit}
        className="bg-white p-8 rounded-lg shadow-lg w-full max-w-lg"
      >
        <h2 className="text-2xl font-bold mb-6">Modifier le Mariage</h2>

        <div className="mb-4">
          <label
            className="block text-gray-700 font-semibold mb-2"
            htmlFor="selectMariage"
          >
            Sélectionner un mariage à modifier
          </label>
          <select
            id="selectMariage"
            className="w-full px-3 py-2 border rounded-lg focus:outline-none focus:ring focus:border-blue-300"
            value={selectedMariageId}
            onChange={(e) => setSelectedMariageId(e.target.value)}
            required
          >
            <option value="">Sélectionner un mariage</option>
            {mariages.map((mariage) => (
              <option key={mariage._id} value={mariage._id}>
                {mariage.name}
              </option>
            ))}
          </select>
        </div>

        {mariageData && (
          <>
            <div className="mb-4">
              <label
                className="block text-gray-700 font-semibold mb-2"
                htmlFor="imgBandeau"
              >
                Image Bandeau
              </label>
              <input
                type="file"
                id="imgBandeau"
                className="w-full px-3 py-2 border rounded-lg focus:outline-none focus:ring focus:border-blue-300"
                onChange={handleFileChangeBandeau}
                encType="multipart/form-data"
              />
            </div>
            <button
              type="submit"
              className="w-full bg-blue-500 text-white font-semibold py-2 rounded-lg hover:bg-blue-600 transition duration-200"
            >
              Modifier le Mariage
            </button>
          </>
        )}
      </form>
    </div>
  );
};

export default EditMariage;
