import React, { useState, useEffect } from "react";
import axiosInstance from "../../middleware/axiosInstance";

const DeleteEvent = () => {
  const [events, setEvents] = useState([]);
  const [selectedEventId, setSelectedEventId] = useState("");

  // Fetch all events on component mount
  useEffect(() => {
    const fetchEvents = async () => {
      try {
        const response = await axiosInstance.get("/events");
        setEvents(response.data);
      } catch (error) {
        console.error("Error fetching events:", error);
      }
    };

    fetchEvents();
  }, []);

  const handleDelete = async () => {
    if (!selectedEventId) {
      alert("Please select an event to delete.");
      return;
    }

    try {
      await axiosInstance.delete(`/events/${selectedEventId}`);
      alert("Event deleted successfully!");
      setEvents(events.filter((event) => event._id !== selectedEventId));
      setSelectedEventId("");
    } catch (error) {
      console.error("Error deleting event:", error);
    }
  };

  return (
    <div className="mt-32 flex justify-center">
      <div className="bg-white p-8 rounded-lg shadow-lg w-full max-w-lg">
        <h2 className="text-2xl font-bold mb-6">Supprimer un événement</h2>

        <div className="mb-4">
          <label
            className="block text-gray-700 font-semibold mb-2"
            htmlFor="selectEvent"
          >
            Sélectionner un événement
          </label>
          <select
            id="selectEvent"
            className="w-full px-3 py-2 border rounded-lg focus:outline-none focus:ring focus:border-blue-300"
            value={selectedEventId}
            onChange={(e) => setSelectedEventId(e.target.value)}
            required
          >
            <option value="">Sélectionner un événement</option>
            {events.map((event) => (
              <option key={event._id} value={event._id}>
                {event.name}
              </option>
            ))}
          </select>
        </div>

        <button
          onClick={handleDelete}
          className="w-full bg-red-500 text-white font-semibold py-2 rounded-lg hover:bg-red-600 transition duration-200"
        >
          Supprimer l'événement
        </button>
      </div>
    </div>
  );
};

export default DeleteEvent;
