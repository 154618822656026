import React from "react";
import Titre from "./Titre";
import Offres from "./Offres";
import IdeePrecise from "./IdeePrecise";

function BlockOffres() {
  return (
    <div className="bg-black">
      <Titre />
      <Offres />
      <IdeePrecise />
    </div>
  );
}

export default BlockOffres;
