import React, { useState, useEffect, useRef } from "react";
import axiosInstance from "../../middleware/axiosInstance";
import { Editor } from "@tinymce/tinymce-react";

const EditActivity = () => {
  const [activities, setActivities] = useState([]);
  const [selectedActivityId, setSelectedActivityId] = useState("");
  const [activityData, setActivityData] = useState(null);
  const [name, setName] = useState("");
  const [image, setImage] = useState(null);
  const [imageUrl, setImageUrl] = useState("");

  // Fetch all activities on component mount
  useEffect(() => {
    axiosInstance
      .get("/atelier")
      .then((response) => {
        setActivities(response.data);
      })
      .catch((error) => {
        console.error("Error fetching activities:", error);
      });
  }, []);

  // Fetch selected activity data when selectedActivityId changes
  useEffect(() => {
    if (selectedActivityId) {
      axiosInstance
        .get(`/atelier/${selectedActivityId}`)
        .then((response) => {
          const activityData = response.data;
          setActivityData(activityData);
          setName(activityData.name);
          setImageUrl(activityData.img);
        })
        .catch((error) => {
          console.error("Error fetching activity:", error);
        });
    }
  }, [selectedActivityId]);

  const handleFileChange = (event) => {
    const file = event.target.files[0];
    setImage(file);
    setImageUrl(file.name);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    const uploadFile = async (file) => {
      const formData = new FormData();
      formData.append("file", file);

      const config = {
        headers: { "content-type": "multipart/form-data" },
      };

      try {
        const response = await axiosInstance.post("/upload", formData, config);
        return response.data.filePath;
      } catch (error) {
        console.error("There was an error uploading the file!", error);
        throw error;
      }
    };

    let uploadedImageUrl = imageUrl;

    if (image) {
      uploadedImageUrl = await uploadFile(image);
    }

    try {
      // Prepare activity data
      const updatedActivityData = {
        name,
        img: uploadedImageUrl,
      };

      // Send activity data to the server
      await axiosInstance.put(
        `/atelier/${selectedActivityId}`,
        updatedActivityData
      );
      alert("Activity updated successfully!");
    } catch (error) {
      console.error("There was an error updating the activity!", error);
    }
  };

  return (
    <div className="mt-32 flex justify-center">
      <form
        onSubmit={handleSubmit}
        className="bg-white p-8 rounded-lg shadow-lg w-full max-w-lg"
      >
        <h2 className="text-2xl font-bold mb-6">Modifier l'Activité</h2>

        <div className="mb-4">
          <label
            className="block text-gray-700 font-semibold mb-2"
            htmlFor="selectActivity"
          >
            Sélectionner une activité à modifier
          </label>
          <select
            id="selectActivity"
            className="w-full px-3 py-2 border rounded-lg focus:outline-none focus:ring focus:border-blue-300"
            value={selectedActivityId}
            onChange={(e) => setSelectedActivityId(e.target.value)}
            required
          >
            <option value="">Sélectionner une activité</option>
            {activities.map((activity) => (
              <option key={activity._id} value={activity._id}>
                {activity.name}
              </option>
            ))}
          </select>
        </div>

        {activityData && (
          <>
            <div className="mb-4">
              <label
                className="block text-gray-700 font-semibold mb-2"
                htmlFor="name"
              >
                Nom de l'activité
              </label>
              <input
                type="text"
                id="name"
                className="w-full px-3 py-2 border rounded-lg focus:outline-none focus:ring focus:border-blue-300"
                value={name}
                onChange={(e) => setName(e.target.value)}
                required
              />
            </div>
            <div className="mb-4">
              <label
                className="block text-gray-700 font-semibold mb-2"
                htmlFor="image"
              >
                Image
              </label>
              <input
                type="file"
                id="image"
                className="w-full px-3 py-2 border rounded-lg focus:outline-none focus:ring focus:border-blue-300"
                onChange={handleFileChange}
                encType="multipart/form-data"
              />
            </div>

            <button
              type="submit"
              className="w-full bg-blue-500 text-white font-semibold py-2 rounded-lg hover:bg-blue-600 transition duration-200"
            >
              Modifier l'Activité
            </button>
          </>
        )}
      </form>
    </div>
  );
};

export default EditActivity;
