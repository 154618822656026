import React, { useState, useEffect } from "react";
import axiosInstance from "../../middleware/axiosInstance";

const DeleteLocation = () => {
  const [locations, setLocations] = useState([]);
  const [selectedLocationId, setSelectedLocationId] = useState("");

  // Fetch all locations on component mount
  useEffect(() => {
    const fetchLocations = async () => {
      try {
        const response = await axiosInstance.get("/location");
        setLocations(response.data);
      } catch (error) {
        console.error("Error fetching locations:", error);
      }
    };

    fetchLocations();
  }, []);

  const handleDelete = async () => {
    if (!selectedLocationId) {
      alert("Please select a location to delete.");
      return;
    }

    try {
      await axiosInstance.delete(`/location/${selectedLocationId}`);
      alert("Location deleted successfully!");
      setLocations(
        locations.filter((location) => location._id !== selectedLocationId)
      );
      setSelectedLocationId("");
    } catch (error) {
      console.error("Error deleting location:", error);
    }
  };

  return (
    <div className="mt-32 flex justify-center">
      <div className="bg-white p-8 rounded-lg shadow-lg w-full max-w-lg">
        <h2 className="text-2xl font-bold mb-6">Supprimer un lieu</h2>

        <div className="mb-4">
          <label
            className="block text-gray-700 font-semibold mb-2"
            htmlFor="selectLocation"
          >
            Sélectionner une page location à supprimer
          </label>
          <select
            id="selectLocation"
            className="w-full px-3 py-2 border rounded-lg focus:outline-none focus:ring focus:border-blue-300"
            value={selectedLocationId}
            onChange={(e) => setSelectedLocationId(e.target.value)}
            required
          >
            <option value="">Sélectionner une page location à supprimer</option>
            {locations.map((location) => (
              <option key={location._id} value={location._id}>
                {location.name}
              </option>
            ))}
          </select>
        </div>

        <button
          onClick={handleDelete}
          className="w-full bg-red-500 text-white font-semibold py-2 rounded-lg hover:bg-red-600 transition duration-200"
        >
          Supprimer la page location
        </button>
      </div>
    </div>
  );
};

export default DeleteLocation;
