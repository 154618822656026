import React, { useState, useEffect, useRef } from "react";
import axiosInstance from "../../middleware/axiosInstance";
import { Editor } from "@tinymce/tinymce-react";

function AddSectionActivity() {
  const [activities, setActivities] = useState([]);
  const [selectedActivityId, setSelectedActivityId] = useState("");
  const [sectionTitle, setSectionTitle] = useState("");
  const [sectionSubtitle, setSectionSubtitle] = useState("");
  const [sectionPrice, setSectionPrice] = useState("");
  const [sectionTexte, setSectionTexte] = useState("");
  const editorRef = useRef(null);

  useEffect(() => {
    // Fetch all activities to populate the dropdown
    const fetchActivities = async () => {
      try {
        const response = await axiosInstance.get("/atelier");
        setActivities(response.data);
      } catch (error) {
        console.error("There was an error fetching the activities!", error);
      }
    };

    fetchActivities();
  }, []);

  const onEditorChange = function (a, editor) {
    setSectionTexte(editorRef.current.getContent());
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    try {
      // Prepare section data
      const sectionData = {
        title: sectionTitle,
        sous_titre: sectionSubtitle,
        prix: sectionPrice,
        texte: sectionTexte,
      };

      // Send section data to the server
      await axiosInstance.post(
        `sectionsAtelier/${selectedActivityId}`,
        sectionData
      );
      alert("Section added successfully!");

      // Clear form
      setSelectedActivityId("");
      setSectionTitle("");
      setSectionSubtitle("");
      setSectionPrice("");
      setSectionTexte("");
    } catch (error) {
      console.error("There was an error adding the section!", error);
    }
  };

  return (
    <div className="mt-32 flex justify-center">
      <form
        onSubmit={handleSubmit}
        className="bg-white p-8 rounded-lg shadow-lg w-full max-w-lg"
      >
        <h2 className="text-2xl font-bold mb-6">
          Ajouter une Section d'Activité
        </h2>
        <div className="mb-4">
          <label
            className="block text-gray-700 font-semibold mb-2"
            htmlFor="activity"
          >
            Sélectionner une activité
          </label>
          <select
            id="activity"
            className="w-full px-3 py-2 border rounded-lg focus:outline-none focus:ring focus:border-blue-300"
            value={selectedActivityId}
            onChange={(e) => setSelectedActivityId(e.target.value)}
            required
          >
            <option value="">Sélectionner une activité</option>
            {activities.map((activity) => (
              <option key={activity._id} value={activity._id}>
                {activity.name}
              </option>
            ))}
          </select>
        </div>
        <div className="mb-4">
          <label
            className="block text-gray-700 font-semibold mb-2"
            htmlFor="sectionTitle"
          >
            Titre de la section
          </label>
          <input
            type="text"
            id="sectionTitle"
            className="w-full px-3 py-2 border rounded-lg focus:outline-none focus:ring focus:border-blue-300"
            value={sectionTitle}
            onChange={(e) => setSectionTitle(e.target.value)}
            required
          />
        </div>
        <div className="mb-4">
          <label
            className="block text-gray-700 font-semibold mb-2"
            htmlFor="sectionSubtitle"
          >
            Sous-titre de la section
          </label>
          <input
            type="text"
            id="sectionSubtitle"
            className="w-full px-3 py-2 border rounded-lg focus:outline-none focus:ring focus:border-blue-300"
            value={sectionSubtitle}
            onChange={(e) => setSectionSubtitle(e.target.value)}
            required
          />
        </div>
        <div className="mb-4">
          <label
            className="block text-gray-700 font-semibold mb-2"
            htmlFor="sectionPrice"
          >
            Prix
          </label>
          <input
            type="text"
            id="sectionPrice"
            className="w-full px-3 py-2 border rounded-lg focus:outline-none focus:ring focus:border-blue-300"
            value={sectionPrice}
            onChange={(e) => setSectionPrice(e.target.value)}
            required
          />
        </div>
        <div className="mb-4">
          <label className="text-gray-700 font-semibold mb-2">Texte</label>
          <Editor
            apiKey="qechaaksdccwyq9o7krr71livdg5etn52i5krhlle0oszh9h"
            onInit={(_evt, editor) => (editorRef.current = editor)}
            onEditorChange={onEditorChange}
            init={{
              height: 500,
              menubar: false,
              plugins: [
                "advlist",
                "autolink",
                "lists",
                "link",
                "image",
                "charmap",
                "preview",
                "anchor",
                "searchreplace",
                "visualblocks",
                "code",
                "fullscreen",
                "insertdatetime",
                "media",
                "table",
                "code",
                "help",
                "wordcount",
              ],
              toolbar:
                "undo redo | formatselect | bold italic underline | \
              alignleft aligncenter alignright alignjustify | \
              bullist numlist outdent indent | removeformat | help",
            }}
          />
        </div>
        <button
          type="submit"
          className="w-full bg-blue-500 text-white font-semibold py-2 rounded-lg hover:bg-blue-600 transition duration-200"
        >
          Ajouter la Section
        </button>
      </form>
    </div>
  );
}

export default AddSectionActivity;
