import React from "react";

function MentionsLegales() {
  return (
    <div className="bg-black text-white pb-16">
      <div className="  py-8 mt-16">
        <div className="container mx-auto px-4">
          <h1 className="text-4xl font-bold mb-8">Mentions Légales</h1>
          <p className="mb-4">
            <strong>Éditeur du site :</strong> <br />
            Nom de l'entreprise: Merveilleux Moment <br />
            Adresse: 8 rue vautier 94340 JOINVILLE LE PONT <br />
            Téléphone: 06 84 30 21 12 <br />
            Email: contact@merveilleuxmoment.fr
          </p>
          <p className="mb-4">
            <strong>Hébergement :</strong> <br />
            Nom de l'hébergeur: o2switch <br />
            Adresse de l'hébergeur: 222-224 Boulevard Gustave Flaubert, 63000
            Clermont-Ferrand, France <br />
            Téléphone: 04 44 44 60 40 <br />
            Email: support@o2switch.fr
          </p>
          <p className="mb-4">
            <strong>Propriété intellectuelle :</strong> <br />
            Le contenu du site (textes, images, photos, vidéos, etc.) est la
            propriété exclusive de Merveilleux Moment, sauf mention contraire.
            Toute reproduction, distribution, modification, adaptation,
            retransmission ou publication, même partielle, de ces différents
            éléments est strictement interdite sans l'accord exprès par écrit de
            Merveilleux Moment.
          </p>
          <p className="mb-4">
            <strong>Responsabilité :</strong> <br />
            Merveilleux Moment ne peut être tenue responsable des dommages
            directs ou indirects causés au matériel de l'utilisateur, lors de
            l'accès au site www.merveilleuxmoment.fr, et résultant soit de
            l'utilisation d'un matériel ne répondant pas aux spécifications
            indiquées, soit de l'apparition d'un bug ou d'une incompatibilité.
          </p>
          <p className="mb-4">
            <strong>Données personnelles :</strong> <br />
            Conformément à la loi informatique et libertés du 6 janvier 1978,
            vous disposez d'un droit d'accès, de rectification et de suppression
            des données personnelles vous concernant, en effectuant votre
            demande par écrit à l'adresse suivante :
            contact@merveilleuxmoment.fr.
          </p>
          <p className="mb-4">
            <strong>Cookies :</strong> <br />
            La navigation sur le site www.merveilleuxmoment.fr est susceptible
            de provoquer l’installation de cookie(s) sur l’ordinateur de
            l’utilisateur. Un cookie est un fichier de petite taille, qui ne
            permet pas l’identification de l’utilisateur, mais qui enregistre
            des informations relatives à la navigation d’un ordinateur sur un
            site.
          </p>
          <p className="mb-4">
            <strong>Litiges :</strong> <br />
            Les présentes conditions du site www.merveilleuxmoment.fr sont
            régies par les lois françaises et toute contestation ou litiges qui
            pourraient naître de l'interprétation ou de l'exécution de celles-ci
            seront de la compétence exclusive des tribunaux dont dépend le siège
            social de l'entreprise.
          </p>
        </div>
      </div>
      <div className="container mx-auto px-4 mt-8">
        <p className="mb-4">
          <strong>Objet du Site</strong> <br />
          Le contenu de ce site est à votre disposition à titre d’information.
        </p>
        <p className="mb-4">
          <strong>Collecte de l’information</strong> <br />
          Je recueille des informations lorsque vous m’envoyez un mail via le
          formulaire de contact. Les informations recueillies incluent votre nom
          et votre adresse e-mail. Ma boite webmail enregistre automatiquement
          et stocke ces informations.
        </p>
        <p className="mb-4">
          <strong>Utilisation des informations</strong> <br />
          Toutes les informations que je recueille sont utilisées pour vous
          contacter par e-mail aux fins de prospection ou de communication. Par
          défaut et sauf maintien de la relation contractuelle, vos données
          personnelles seront détruites dans trois ans. À tout moment à l’issue
          de la période de garantie, vous pouvez demander expressément la
          suppression de toutes les données personnelles vous concernant.
        </p>
        <p className="mb-4">
          <strong>Divulgation à des tiers</strong> <br />
          Je ne vends pas, n’échange pas et ne transfère pas vos informations
          personnelles identifiables à des tiers. Cela ne comprend pas les
          tierce-parties de confiance qui m’aident à exploiter mon site Web ou à
          mener mes affaires, tant que ces parties conviennent de garder ces
          informations confidentielles. Exceptionnellement, il peut être
          nécessaire de partager des informations lorsque la loi ou les
          autorités judiciaires viennent à l’exiger, notamment lors de mesures
          concernant des activités illégales, des fraudes présumées, des menaces
          potentielles à la sécurité physique de toute personne, ou en cas de
          violations de nos conditions d’utilisation ou de droits des tiers.
        </p>
        <p>
          <strong>Protection des informations</strong> <br />
          Je mets en œuvre une variété de mesures de sécurité pour préserver la
          protection de vos informations personnelles. Je protège également vos
          informations stockées hors ligne. Seul le propriétaire du site a accès
          aux informations personnelles identifiables. Les ordinateurs et
          serveurs utilisés pour stocker des informations personnelles
          identifiables sont conservés dans un environnement sécurisé. En cas de
          vol de mes données, toutes les actions possibles seraient menées afin
          de récupérer ces données et mettre fin aux agissements de ses auteurs.
        </p>
      </div>
    </div>
  );
}

export default MentionsLegales;
