import React, { useState, useEffect, useRef, useCallback } from "react";
import axiosInstance from "../../middleware/axiosInstance";
import { Editor } from "@tinymce/tinymce-react";

const EditMaryline = () => {
  const [selectedMarylineId, setSelectedMarylineId] = useState("");
  const [marylineData, setMarylineData] = useState(null);
  const [titre, setTitre] = useState("");
  const [image, setImage] = useState(null);
  const [imageUrl, setImageUrl] = useState("");
  const [texte1, setDescription] = useState("");
  const [texte2, setTexte2] = useState("");
  const [texte3, setTexte3] = useState("");

  const editorRef = useRef(null);
  const editorRef2 = useRef(null);

  // Fetch all events on component mount
  useEffect(() => {
    axiosInstance
      .get("/maryline")
      .then((response) => {
        setMarylineData(response.data[0]);
        setTitre(response.data[0].titre);
        setImageUrl(response.data[0].image);
        setDescription(response.data[0].texte1);
        setTexte2(response.data[0].texte2);
        setTexte3(response.data[0].texte3);
      })
      .catch((error) => {
        console.error("Error fetching events:", error);
      });
  }, []);

  const handleFileChange = (event) => {
    const file = event.target.files[0];
    setImage(file);
    setImageUrl(file.titre);
  };

  const onEditorChange = useCallback((content) => {
    setDescription(content);
  }, []);

  const onEditorChange2 = useCallback((content) => {
    setTexte2(content);
  }, []);

  const onEditorChange3 = useCallback((content) => {
    setTexte3(content);
  }, []);

  const handleSubmit = async (e) => {
    e.preventDefault();

    const uploadFile = async (file) => {
      const formData = new FormData();
      formData.append("file", file);

      const config = {
        headers: { "content-type": "multipart/form-data" },
      };

      try {
        const response = await axiosInstance.post("/upload", formData, config);
        return response.data.filePath;
      } catch (error) {
        console.error("There was an error uploading the file!", error);
        throw error;
      }
    };

    let uploadedImageUrl = imageUrl;

    if (image) {
      uploadedImageUrl = await uploadFile(image);
    }

    try {
      // Prepare maryline data
      const updatedMarylineData = {
        titre,
        img: uploadedImageUrl,
        texte1,
        texte2: texte2,
      };

      // Send maryline data to the server
      await axiosInstance.put(
        `/maryline/${marylineData._id}`,
        updatedMarylineData
      );
      alert("Maryline updated successfully!");
    } catch (error) {
      console.error("There was an error updating the maryline!", error);
    }
  };

  return (
    <div className="mt-32 flex justify-center">
      <form
        onSubmit={handleSubmit}
        className="bg-white p-8 rounded-lg shadow-lg w-full max-w-lg"
      >
        <h2 className="text-2xl font-bold mb-6">
          Modifier la section "Maryline"
        </h2>

        {marylineData && (
          <>
            <div className="mb-4">
              <label
                className="block text-gray-700 font-semibold mb-2"
                htmlFor="titre"
              >
                Nom de l'événement
              </label>
              <input
                type="text"
                id="titre"
                className="w-full px-3 py-2 border rounded-lg focus:outline-none focus:ring focus:border-blue-300"
                value={titre}
                onChange={(e) => setTitre(e.target.value)}
                required
              />
            </div>
            <div className="mb-4">
              <label
                className="block text-gray-700 font-semibold mb-2"
                htmlFor="image"
              >
                Image
              </label>
              <input
                type="file"
                id="image"
                className="w-full px-3 py-2 border rounded-lg focus:outline-none focus:ring focus:border-blue-300"
                onChange={handleFileChange}
                encType="multipart/form-data"
              />
            </div>

            <div className="mb-4">
              <label className="text-gray-700 font-semibold mb-2">
                Description
              </label>
              <Editor
                apiKey="qechaaksdccwyq9o7krr71livdg5etn52i5krhlle0oszh9h"
                onInit={(_evt, editor) => (editorRef.current = editor)}
                value={texte1}
                onEditorChange={onEditorChange}
                init={{
                  height: 500,
                  menubar: false,
                  plugins: [
                    "advlist",
                    "autolink",
                    "lists",
                    "link",
                    "image",
                    "charmap",
                    "preview",
                    "anchor",
                    "searchreplace",
                    "visualblocks",
                    "code",
                    "fullscreen",
                    "insertdatetime",
                    "media",
                    "table",
                    "code",
                    "help",
                    "wordcount",
                  ],
                  toolbar:
                    "undo redo | formatselect | bold italic underline | \
              alignleft aligncenter alignright alignjustify | \
              bullist numlist outdent indent | removeformat | help",
                }}
              />
            </div>
            <div className="mb-4">
              <label className="text-gray-700 font-semibold mb-2">
                Texte frais d'installation etc
              </label>
              <Editor
                apiKey="qechaaksdccwyq9o7krr71livdg5etn52i5krhlle0oszh9h"
                onInit={(_evt, editor) => (editorRef2.current = editor)}
                onEditorChange={onEditorChange2}
                value={texte2}
                init={{
                  height: 500,
                  menubar: false,
                  plugins: [
                    "advlist",
                    "autolink",
                    "lists",
                    "link",
                    "image",
                    "charmap",
                    "preview",
                    "anchor",
                    "searchreplace",
                    "visualblocks",
                    "code",
                    "fullscreen",
                    "insertdatetime",
                    "media",
                    "table",
                    "code",
                    "help",
                    "wordcount",
                  ],
                  toolbar:
                    "undo redo | formatselect | bold italic underline | \
              alignleft aligncenter alignright alignjustify | \
              bullist numlist outdent indent | removeformat | help",
                }}
              />
            </div>
            <div className="mb-4">
              <label className="text-gray-700 font-semibold mb-2">
                Texte frais d'installation etc
              </label>
              <Editor
                apiKey="qechaaksdccwyq9o7krr71livdg5etn52i5krhlle0oszh9h"
                onInit={(_evt, editor) => (editorRef2.current = editor)}
                onEditorChange={onEditorChange3}
                value={texte3}
                init={{
                  height: 500,
                  menubar: false,
                  plugins: [
                    "advlist",
                    "autolink",
                    "lists",
                    "link",
                    "image",
                    "charmap",
                    "preview",
                    "anchor",
                    "searchreplace",
                    "visualblocks",
                    "code",
                    "fullscreen",
                    "insertdatetime",
                    "media",
                    "table",
                    "code",
                    "help",
                    "wordcount",
                  ],
                  toolbar:
                    "undo redo | formatselect | bold italic underline | \
              alignleft aligncenter alignright alignjustify | \
              bullist numlist outdent indent | removeformat | help",
                }}
              />
            </div>
            <button
              type="submit"
              className="w-full bg-blue-500 text-white font-semibold py-2 rounded-lg hover:bg-blue-600 transition duration-200"
            >
              Modifier l'Événement
            </button>
          </>
        )}
      </form>
    </div>
  );
};

export default EditMaryline;
