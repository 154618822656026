import React from "react";
import parse from "html-react-parser";

function FondNoir({ title, imageSrc, texte }) {
  return (
    <div className="bg-black py-16 ">
      <h2 className="md:text-6xl text-4xl text-center text-green mb-16 Museo w-full">
        {title}
      </h2>
      <div className="container mx-auto flex flex-col lg:flex-row w-[90%] lg:w-[100%]">
        <div className="lg:w-1/2 flex text-center flex-col justify-center items-start p-8 text-white">
          <div className="mb-4 mx-auto Museo text-2xl">{parse(texte)}</div>
        </div>
        <div className="lg:w-1/2 flex justify-center mx-auto items-center ">
          <img
            src={imageSrc}
            alt="Description de l'image"
            className="rounded-[90px] h-[400px] object-cover"
          />
        </div>
      </div>
    </div>
  );
}

export default FondNoir;
