import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import axiosInstance from "../middleware/axiosInstance";
import parse from "html-react-parser";
import { Helmet } from "react-helmet";

function LocationDetailScreen({ name: name2 }) {
  const { name: nameFromParams } = useParams();
  const name = name2 || nameFromParams;

  const [location, setLocation] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  const getImageURL = (imageName) =>
    `${process.env.REACT_APP_URL}image/${imageName}`;

  useEffect(() => {
    axiosInstance
      .get(`/location/name/${name}`)
      .then((response) => {
        setLocation(response.data);
        setLoading(false);
      })
      .catch((error) => {
        setError(error.message);
        setLoading(false);
      });
  }, [name]);

  if (loading) {
    return <div>Loading...</div>;
  }

  if (error) {
    return <div>Error: {error}</div>;
  }

  if (!location) {
    return <div>No location data available</div>;
  }

  return (
    <div className=" mx-auto mt-16 p-8">
      <Helmet>
        <title>{location.name} - Merveilleux Moments</title>
        <meta
          name="description"
          content="Vous chercher a louer de la decoration pour votre anniversaire ou votre mariage ou un evenement solenel comme une communion ?"
        />
        <meta
          name="keywords"
          content="location, événements, décoration, mariage, anniversaire, Val-de-Marne, Maryline, Merveilleux Moments"
        />
        <meta property="og:title" content="location - Merveilleux Moments" />
        <meta
          property="og:description"
          content="Vous chercher a louer de la decoration pour votre anniversaire ou votre mariage ou un evenement solenel comme une communion ?"
        />
        <meta
          property="og:image"
          content="https://votre-site.com/images/og-contact-image.jpg"
        />
        <meta property="og:url" content="https://votre-site.com/contact" />
        <meta property="og:type" content="website" />
        <meta name="twitter:card" content="summary_large_image" />
        <meta
          name="twitter:title"
          content="Contactez-nous - Merveilleux Moments"
        />
        <meta
          name="twitter:description"
          content="Vous chercher a louer de la decoration pour votre anniversaire ou votre mariage ou un evenement solenel comme une communion ?"
        />
        <meta
          name="twitter:image"
          content="https://votre-site.com/images/twitter-contact-image.jpg"
        />
        <meta name="twitter:site" content="@votre_compte" />
      </Helmet>
      <h1 className="text-6xl font-bold MuseoBold text-center mb-12">
        {location.name}
      </h1>
      {location.sectionLocation_id.map((section, index) => (
        <section
          key={index}
          className="flex flex-col lg:flex-row lg:space-x-4 items-center Museo mb-24"
        >
          <div className="lg:w-2/3">
            <h2 className="text-3xl font-bold mb-4">{section.title}</h2>
            <h3 className="text-2xl mb-4">{section.sous_titre}</h3>
            <div className="text-xl mb-4">{parse(section.texte)}</div>
            <div className="mb-4">{section.prix}</div>
          </div>
          <div className="lg:w-1/3">
            <img
              className="rounded-[90px] lg:ml-10 w-full md:w-[450px]"
              src={getImageURL(section.img)}
              alt={section.name}
            />
          </div>
        </section>
      ))}
    </div>
  );
}

export default LocationDetailScreen;
