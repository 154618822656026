import React, { useState } from "react";
import axiosInstance from "../../middleware/axiosInstance";

function AddLocation() {
  const [name, setName] = useState("");

  const handleSubmit = async (e) => {
    e.preventDefault();

    try {
      // Prepare location data
      const locationData = {
        name: name,
      };

      // Send location data to the server
      await axiosInstance.post("/location", locationData);
      alert("Location added successfully!");

      // Clear form
      setName("");
    } catch (error) {
      console.error("There was an error adding the location!", error);
    }
  };

  return (
    <div className="mt-32 flex justify-center">
      <form
        onSubmit={handleSubmit}
        className="bg-white p-8 rounded-lg shadow-lg w-full max-w-lg"
      >
        <h2 className="text-2xl font-bold mb-6">Ajouter une Location</h2>
        <div className="mb-4">
          <label
            className="block text-gray-700 font-semibold mb-2"
            htmlFor="name"
          >
            Nom de la location
          </label>
          <input
            type="text"
            id="name"
            className="w-full px-3 py-2 border rounded-lg focus:outline-none focus:ring focus:border-blue-300"
            value={name}
            onChange={(e) => setName(e.target.value)}
            required
          />
        </div>

        <button
          type="submit"
          className="w-full bg-blue-500 text-white font-semibold py-2 rounded-lg hover:bg-blue-600 transition duration-200"
        >
          Ajouter la Location
        </button>
      </form>
    </div>
  );
}

export default AddLocation;
