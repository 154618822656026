import React, { useState, useEffect } from "react";
import ContactezNousNoir from "../ContacterNousNoir";
import axiosInstance from "../../middleware/axiosInstance";
import parse from "html-react-parser";

function Maryline() {
  const [maryline, setMaryline] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  const getImageURL = (imageName) =>
    `${process.env.REACT_APP_URL}image/${imageName}`;

  useEffect(() => {
    axiosInstance
      .get(`/maryline`)
      .then((response) => {
        setMaryline(response.data[0]);
        setLoading(false);
      })
      .catch((error) => {
        setError(error.message);
        setLoading(false);
      });
  }, []);

  if (loading) {
    return <div>Loading...</div>;
  }

  if (error) {
    return <div>Error: {error}</div>;
  }

  if (!maryline) {
    return <div>No location data available</div>;
  }
  return (
    <div className="bg-white py-16">
      <div className="container mx-auto flex flex-col lg:flex-row">
        <div className="lg:w-1/2 mx-auto">
          <img
            src={getImageURL(maryline.img)}
            alt={maryline.img}
            className="lg:h-[800px] rounded-[90px]"
          />
        </div>
        <div className="lg:w-1/2 flex flex-col justify-center items-center lg:items-start p-4 lg:p-8 text-center lg:text-left">
          <div className="text-5xl md:text-6xl mb-4 Museo  lg:text-center">
            {parse(maryline.titre)}
          </div>
          <div className="text-2xl md:text-3xl lg:text-3xl mb-4 MohaveBold text-neutral-500 pb-10 mx-auto lg:mx-0 lg:text-center">
            {parse(maryline.sous_titre)}
          </div>
          <div className="mb-4 text-base md:text-lg lg:text-xl Koho">
            {parse(maryline.texte1)}
          </div>
          <div className="mb-4 text-base md:text-lg lg:text-xl KohoBold">
            {parse(maryline.texte2)}
          </div>
          <div className="text-base md:text-lg lg:text-xl Koho pb-10">
            {parse(maryline.texte3)}
          </div>
          <ContactezNousNoir />
        </div>
      </div>
      <div clas></div>
    </div>
  );
}

export default Maryline;
