import React from "react";

function Titre() {
  return (
    <>
      <div className="text-center text-white Museo pt-10 pb-10">
        <div className="text-7xl sm:text-8xl md:text-9xl">
          M
          <span className="text-5xl sm:text-6xl md:text-7xl">
            erveilleux Moment
          </span>
        </div>
        <div className="text-2xl">By Maryline</div>
      </div>
      <div className="text-center text-white Museo text-4xl pb-36">
        Créatrice d'événements magiques
      </div>
    </>
  );
}

export default Titre;
