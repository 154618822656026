import React, { useState, useEffect, useRef, useCallback } from "react";
import axiosInstance from "../../middleware/axiosInstance";
import { Editor } from "@tinymce/tinymce-react";

const EditSectionMariage = () => {
  const [mariages, setMariages] = useState([]);
  const [sections, setSections] = useState([]);
  const [selectedMariageId, setSelectedMariageId] = useState("");
  const [selectedSectionId, setSelectedSectionId] = useState("");
  const [sectionData, setSectionData] = useState(null);
  const [sectionTitle, setSectionTitle] = useState("");
  const [sectionTexte, setSectionTexte] = useState("");
  const [sectionTexteRDV, setSectionTexteRDV] = useState("");
  const [sectionTexteFrais, setSectionTexteFrais] = useState("");
  const editorRef = useRef(null);
  const editorRefRDV = useRef(null);
  const editorRefFrais = useRef(null);

  useEffect(() => {
    const fetchMariages = async () => {
      try {
        const response = await axiosInstance.get("/mariage");
        setMariages(response.data);
      } catch (error) {
        console.error("There was an error fetching the mariages!", error);
      }
    };

    fetchMariages();
  }, []);

  const handleMariageChange = (event) => {
    setSelectedMariageId(event.target.value);
    setSelectedSectionId("");
    setSectionData(null);
    if (event.target.value) {
      const selectedMariage = mariages.find(
        (e) => e._id === event.target.value
      );
      setSections(selectedMariage.sectionMariage_id);
    } else {
      setSections([]);
    }
  };

  const handleSectionChange = (event) => {
    setSelectedSectionId(event.target.value);
    const section = sections.find(
      (section) => section._id === event.target.value
    );
    if (section) {
      setSectionData(section);
      setSectionTitle(section.title);
      setSectionTexte(section.texte);
      setSectionTexteRDV(section.texte_rdv);
      setSectionTexteFrais(section.texte_frais);
    }
  };

  const onEditorChange = useCallback((content) => {
    setSectionTexte(content);
  }, []);

  const onEditorChangeRDV = useCallback((content) => {
    setSectionTexteRDV(content);
  }, []);

  const onEditorChangeFrais = useCallback((content) => {
    setSectionTexteFrais(content);
  }, []);

  const handleSubmit = async (e) => {
    e.preventDefault();

    try {
      const updatedSectionData = {
        title: sectionTitle,
        texte: sectionTexte,
        texte_rdv: sectionTexteRDV,
        texte_frais: sectionTexteFrais,
      };

      await axiosInstance.put(
        `/sectionsMariage/${selectedSectionId}`,
        updatedSectionData
      );
      alert("Section updated successfully!");
    } catch (error) {
      console.error("There was an error updating the section!", error);
    }
  };

  return (
    <div className="mt-32 flex justify-center">
      <form
        onSubmit={handleSubmit}
        className="bg-white p-8 rounded-lg shadow-lg w-full max-w-lg"
      >
        <h2 className="text-2xl font-bold mb-6">
          Modifier une Section Mariage
        </h2>
        <div className="mb-4">
          <label
            className="block text-gray-700 font-semibold mb-2"
            htmlFor="selectMariage"
          >
            Sélectionner un mariage
          </label>
          <select
            id="selectMariage"
            className="w-full px-3 py-2 border rounded-lg focus:outline-none focus:ring focus:border-blue-300"
            value={selectedMariageId}
            onChange={handleMariageChange}
            required
          >
            <option value="">Sélectionner un mariage</option>
            {mariages.map((mariage) => (
              <option key={mariage._id} value={mariage._id}>
                {mariage.name}
              </option>
            ))}
          </select>
        </div>

        {selectedMariageId && (
          <div className="mb-4">
            <label
              className="block text-gray-700 font-semibold mb-2"
              htmlFor="selectSection"
            >
              Sélectionner une section
            </label>
            <select
              id="selectSection"
              className="w-full px-3 py-2 border rounded-lg focus:outline-none focus:ring focus:border-blue-300"
              value={selectedSectionId}
              onChange={handleSectionChange}
              required
            >
              <option value="">Sélectionner une section</option>
              {sections.map((section) => (
                <option key={section._id} value={section._id}>
                  {section.title}
                </option>
              ))}
            </select>
          </div>
        )}

        {sectionData && (
          <>
            <div className="mb-4">
              <label
                className="block text-gray-700 font-semibold mb-2"
                htmlFor="sectionTitle"
              >
                Titre de la section
              </label>
              <input
                type="text"
                id="sectionTitle"
                className="w-full px-3 py-2 border rounded-lg focus:outline-none focus:ring focus:border-blue-300"
                value={sectionTitle}
                onChange={(e) => setSectionTitle(e.target.value)}
                required
              />
            </div>

            <div className="mb-4">
              <label
                className="block text-gray-700 font-semibold mb-2"
                htmlFor="sectionTexte"
              >
                Texte de la section
              </label>
              <Editor
                apiKey="qechaaksdccwyq9o7krr71livdg5etn52i5krhlle0oszh9h"
                id="sectionTexte"
                onInit={(evt, editor) => (editorRef.current = editor)}
                value={sectionTexte}
                init={{
                  height: 300,
                  menubar: false,
                  plugins: [
                    "advlist autolink lists link image charmap print preview anchor",
                    "searchreplace visualblocks code fullscreen",
                    "insertdatetime media table paste code help wordcount",
                  ],
                  toolbar:
                    "undo redo | formatselect | bold italic underline | \
                    alignleft aligncenter alignright alignjustify | \
                    bullist numlist outdent indent | removeformat | help",
                }}
                onEditorChange={onEditorChange}
              />
            </div>

            <div className="mb-4">
              <label
                className="block text-gray-700 font-semibold mb-2"
                htmlFor="sectionTexteRDV"
              >
                Texte de RDV
              </label>
              <Editor
                apiKey="qechaaksdccwyq9o7krr71livdg5etn52i5krhlle0oszh9h"
                id="sectionTexteRDV"
                onInit={(evt, editor) => (editorRefRDV.current = editor)}
                value={sectionTexteRDV}
                init={{
                  height: 300,
                  menubar: false,
                  plugins: [
                    "advlist autolink lists link image charmap print preview anchor",
                    "searchreplace visualblocks code fullscreen",
                    "insertdatetime media table paste code help wordcount",
                  ],
                  toolbar:
                    "undo redo | formatselect | bold italic underline | \
                    alignleft aligncenter alignright alignjustify | \
                    bullist numlist outdent indent | removeformat | help",
                }}
                onEditorChange={onEditorChangeRDV}
              />
            </div>

            <div className="mb-4">
              <label
                className="block text-gray-700 font-semibold mb-2"
                htmlFor="sectionTexteFrais"
              >
                Texte de Frais
              </label>
              <Editor
                apiKey="qechaaksdccwyq9o7krr71livdg5etn52i5krhlle0oszh9h"
                id="sectionTexteFrais"
                onInit={(evt, editor) => (editorRefFrais.current = editor)}
                value={sectionTexteFrais}
                init={{
                  height: 300,
                  menubar: false,
                  plugins: [
                    "advlist autolink lists link image charmap print preview anchor",
                    "searchreplace visualblocks code fullscreen",
                    "insertdatetime media table paste code help wordcount",
                  ],
                  toolbar:
                    "undo redo | formatselect | bold italic underline | \
                    alignleft aligncenter alignright alignjustify | \
                    bullist numlist outdent indent | removeformat | help",
                }}
                onEditorChange={onEditorChangeFrais}
              />
            </div>

            <button
              type="submit"
              className="w-full bg-blue-500 text-white font-semibold py-2 rounded-lg hover:bg-blue-600 transition duration-200"
            >
              Modifier la Section
            </button>
          </>
        )}
      </form>
    </div>
  );
};

export default EditSectionMariage;
