import React, { useState, useEffect } from "react";
import axiosInstance from "../middleware/axiosInstance";
import LocationDetailScreen from "./LocationDetailScreen";

function AllLocation() {
  const [locations, setLocations] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    axiosInstance
      .get(`/location`)
      .then((response) => {
        setLocations(response.data);
        setLoading(false);
      })
      .catch((error) => {
        setError(error.message);
        setLoading(false);
      });
  }, []);

  if (loading) {
    return <div>Loading...</div>;
  }

  if (error) {
    return <div>Error: {error}</div>;
  }

  if (!locations.length) {
    return <div>No location data available</div>;
  }

  return (
    <>
      {locations.map((location, index) => (
        <div key={index}>
          <LocationDetailScreen name={location.name} />
        </div>
      ))}
    </>
  );
}

export default AllLocation;
