import React from "react";
import { useNavigate } from "react-router-dom";

function ContactezNousNoir() {
  const navigate = useNavigate();

  const handleClick = () => {
    navigate("/contact");
  };

  return (
    <div className="flex justify-center mx-auto Mohave">
      <div
        className="w-80 sm:w-96 h-20 relative cursor-pointer"
        onClick={handleClick}
      >
        <div className="w-44 sm:w-52 h-px left-[101px] top-[-10px] sm:left-[159px] sm:top-0 absolute border border-stone-950"></div>
        <div className="w-44 sm:w-52 h-px left-[101px] top-[-9px] sm:left-[159px] sm:top-[1px] absolute border border-stone-950"></div>
        <div className="w-8 h-px left-[277px] top-[-10px] sm:left-[367px] sm:top-[2px] absolute origin-top-left rotate-90 border border-stone-950"></div>
        <div className="w-8 h-px left-[276px] top-[-10px] sm:left-[366px] sm:top-[2px] absolute origin-top-left rotate-90 border border-stone-950"></div>
        <div className="w-44 sm:w-52  left-[55px] top-[40px] h-px sm:left-[1px] sm:top-[81px] absolute border border-stone-950"></div>
        <div className="w-44 sm:w-52 h-px left-[55px] top-[41px] sm:left-[1px] sm:top-[80px] absolute border border-stone-950"></div>
        <div className="w-9 h-px left-[55px] top-[43px] sm:left-[1px] sm:top-[81px] absolute origin-top-left -rotate-90 border border-stone-950"></div>
        <div className="w-9 h-px left-[54px] top-[43px] sm:left-[1px] sm:top-[81px] absolute origin-top-left -rotate-90 border border-stone-950"></div>
        <div className="w-80 sm:w-96 left-[1px] sm:top-[20px] absolute text-center text-stone-950 sm:text-4xl text-2xl">
          CONTACTEZ-NOUS
        </div>
      </div>
    </div>
  );
}

export default ContactezNousNoir;
