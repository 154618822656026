import React from "react";
import { Link } from "react-router-dom";

function Offres() {
  return (
    <div className="md:mb-52 mb-32 ">
      <div className="flex items-center justify-center mb-10 flex-row space-y-0 space-x-4">
        <div className="h-1 w-32 md:w-52 bg-white"></div>
        <div className="px-4">
          <div className="text-center text-white MohaveBold text-2xl md:text-3xl lg:text-5xl">
            Découvrez nos offres
          </div>
        </div>
        <div className="h-1 w-32 md:w-52 bg-white"></div>
      </div>
      <div className="flex flex-col justify-center md:flex-row md:space-x-4  text-center text-white text-xl md:text-2xl lg:text-3xl Museo">
        <Link
          to="/evenement"
          className="text-white xsm:mx-auto md:mx-0 bg-transparent border border-lightgreen xsm:py-2 xsm:px-4 mb-4 md:mb-0 max-w-40 lg:pr-48 md:pr-40"
        >
          Événements
        </Link>
        <Link
          to="/activite"
          className="text-white xsm:mx-auto bg-transparent border border-lightgreen py-2 px-4 mb-4 md:mb-0 max-w-40"
        >
          Ateliers/cours
        </Link>
        <Link
          to="/location"
          className="text-white xsm:mx-auto bg-transparent border border-lightgreen py-2 px-4 max-w-40"
        >
          Location
        </Link>
      </div>
    </div>
  );
}

export default Offres;
