import React from "react";
import parse from "html-react-parser";
function FondBlanc({ title, imageSrc, texte }) {
  return (
    <div className="bg-white py-16 ">
      <h2 className="md:text-6xl text-4xl text-center text-green mb-16 MuseoBold w-full ">
        {title}
      </h2>
      <div className="container mx-auto flex flex-col lg:flex-row w-[90%] lg:w-[100%]">
        <div className="lg:w-1/2 flex justify-center items-center">
          <img
            src={imageSrc}
            alt="Description de l'image"
            className="rounded-[90px] h-[400px] object-cover"
          />
        </div>
        <div className="lg:w-1/2 flex text-center flex-col justify-center items-start p-8 text-black">
          <div className="mb-4 text-2xl Museo mx-auto">{parse(texte)}</div>
        </div>
      </div>
    </div>
  );
}

export default FondBlanc;
