import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import axiosInstance from "../middleware/axiosInstance";

const AdminScreen = () => {
  const [events, setEvents] = useState("");

  useEffect(() => {
    axiosInstance
      .get("events")
      .then((response) => {
        setEvents(response.data);
      })
      .catch((error) => {
        console.error("Error fetching events:", error);
      });
  }, []);

  return (
    <div className="mt-32 px-8 py-4 bg-gray-100 min-h-screen">
      <h2 className="text-3xl font-bold text-center mb-8">
        Panel d'Administration
      </h2>
      <div className="bg-white p-6 rounded-lg shadow-md mb-8">
        <h3 className="text-xl font-semibold mb-4 text-gray-700">
          Ajouter un élément :
        </h3>
        <ul className="list-disc pl-5 space-y-2">
          <li>
            <Link
              to="/admin/add/event"
              className="text-blue-500 hover:underline"
            >
              Ajouter un événement
            </Link>
          </li>
          <li>
            <Link
              to="/admin/add/eventSection"
              className="text-blue-500 hover:underline"
            >
              Ajouter une section événement
            </Link>
          </li>
          <li>
            <Link
              to="/admin/add/activity"
              className="text-blue-500 hover:underline"
            >
              Ajouter une activité
            </Link>
          </li>
          <li>
            <Link
              to="/admin/add/activitySection"
              className="text-blue-500 hover:underline"
            >
              Ajouter une section activité
            </Link>
          </li>
          <li>
            <Link
              to="/admin/add/location"
              className="text-blue-500 hover:underline"
            >
              Ajouter une location
            </Link>
          </li>
          <li>
            <Link
              to="/admin/add/locationSection"
              className="text-blue-500 hover:underline"
            >
              Ajouter une section location
            </Link>
          </li>
          {/* Ajoutez d'autres options selon vos besoins */}
        </ul>
      </div>
      <div className="bg-white p-6 rounded-lg shadow-md mb-8">
        <h3 className="text-xl font-semibold mb-4 text-gray-700">
          Modifier un élément existant :
        </h3>
        <ul className="list-disc pl-5 space-y-2 mb-6">
          <li>
            <Link
              to="/admin/edit/mariage"
              className="text-blue-500 hover:underline"
            >
              Modifier le contenu (image bandeau) de la page mariage
            </Link>
          </li>
          <li>
            <Link
              to="/admin/edit/mariageSection"
              className="text-blue-500 hover:underline"
            >
              Modifier le contenu des sections de la page mariage
            </Link>
          </li>
        </ul>
        <ul className="list-disc pl-5 space-y-2">
          <li>
            <Link
              to="/admin/edit/event"
              className="text-blue-500 hover:underline"
            >
              Modifier un événement
            </Link>
          </li>
          <li>
            <Link
              to="/admin/edit/eventSection"
              className="text-blue-500 hover:underline"
            >
              Modifier une section événement
            </Link>
          </li>
          <li>
            <Link
              to="/admin/edit/activity"
              className="text-blue-500 hover:underline"
            >
              Modifier une activité
            </Link>
          </li>
          <li>
            <Link
              to="/admin/edit/activitySection"
              className="text-blue-500 hover:underline"
            >
              Modifier une section activité
            </Link>
          </li>
          <li>
            <Link
              to="/admin/edit/location"
              className="text-blue-500 hover:underline"
            >
              Modifier une location
            </Link>
          </li>
          <li>
            <Link
              to="/admin/edit/locationSection"
              className="text-blue-500 hover:underline"
            >
              Modifier une section location
            </Link>
          </li>
          <li>
            <Link
              to="/admin/edit/contact"
              className="text-blue-500 hover:underline"
            >
              Modifier la page contact
            </Link>
          </li>
          <li>
            <Link
              to="/admin/edit/maryline"
              className="text-blue-500 hover:underline"
            >
              Modifier la section "maryline" de la page d'acceuil
            </Link>
          </li>
        </ul>
      </div>
      <div className="bg-white p-6 rounded-lg shadow-md">
        <h3 className="text-xl font-semibold mb-4 text-gray-700">
          Supprimer un élément existant :
        </h3>
        <ul className="list-disc pl-5 space-y-2">
          <li>
            <Link
              to="/admin/delete/event"
              className="text-red-500 hover:underline"
            >
              Supprimer un événement
            </Link>
          </li>
          <li>
            <Link
              to="/admin/delete/eventSection"
              className="text-red-500 hover:underline"
            >
              Supprimer une section événement
            </Link>
          </li>
          <li>
            <Link
              to="/admin/delete/activity"
              className="text-red-500 hover:underline"
            >
              Supprimer une activité
            </Link>
          </li>
          <li>
            <Link
              to="/admin/delete/activitySection"
              className="text-red-500 hover:underline"
            >
              Supprimer une section activité
            </Link>
          </li>
          <li>
            <Link
              to="/admin/delete/location"
              className="text-red-500 hover:underline"
            >
              Supprimer une location
            </Link>
          </li>
          <li>
            <Link
              to="/admin/delete/locationSection"
              className="text-red-500 hover:underline"
            >
              Supprimer une section location
            </Link>
          </li>
          {/* Ajoutez d'autres options selon vos besoins */}
        </ul>
      </div>
    </div>
  );
};

export default AdminScreen;
