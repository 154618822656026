import axios from "axios";

// Récupérez l'URL de base depuis les variables d'environnement
const URL = process.env.REACT_APP_URL;

// Créez une instance Axios avec une configuration de base
const axiosInstance = axios.create({
  baseURL: URL, // URL de base de votre API
  withCredentials: true, // Assurez-vous que les cookies sont envoyés avec les requêtes
});

// Ajoutez un intercepteur pour ajouter l'en-tête "Authorization" à chaque requête sortante
axiosInstance.interceptors.request.use(
  (config) => {
    // Récupérez le jeton d'authentification depuis les cookies
    const token = getCookieValue("authorization");
    // Si un jeton existe, ajoutez-le à l'en-tête "Authorization" de la requête
    if (token) {
      config.headers.Authorization = `${token}`;
    }

    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);

// Fonction pour récupérer la valeur d'un cookie par son nom
const getCookieValue = (name) => {
  const value = `; ${document.cookie}`;
  const parts = value.split(`; ${name}=`);
  if (parts.length === 2) return parts.pop().split(";").shift();
  return null;
};

export default axiosInstance;
