import React from "react";
import { Navigate } from "react-router-dom";

const ProtectedRoute = ({ children }) => {
  // Fonction pour récupérer la valeur d'un cookie par son nom
  const getCookieValue = (name) => {
    const value = `; ${document.cookie}`;
    const parts = value.split(`; ${name}=`);
    if (parts.length === 2) return parts.pop().split(";").shift();
    return null;
  };

  // Vérifie si le cookie d'autorisation est présent
  const token = getCookieValue("authorization");
  const isLoggedIn = !!token;

  // Si l'utilisateur est connecté, rend les composants enfants
  if (isLoggedIn) {
    return children;
  } else {
    return <Navigate to="/login" />;
  }
};

export default ProtectedRoute;
