import React, { useState, useRef } from "react";
import axiosInstance from "../../middleware/axiosInstance";

function AddActivity() {
  const [name, setName] = useState("");
  const [image, setImage] = useState("");
  const [imageUrl, setImageUrl] = useState("");

  const handleFileChange = (event) => {
    const file = event.target.files[0];
    setImage(file);
    setImageUrl(file.name);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    const uploadFile = async (file) => {
      const formData = new FormData();
      formData.append("file", file);

      const config = {
        headers: { "content-type": "multipart/form-data" },
      };

      try {
        const response = await axiosInstance.post("/upload", formData, config);
        return response.data.filePath;
      } catch (error) {
        console.error("There was an error uploading the file!", error);
        throw error;
      }
    };

    const imageUrlUploaded = await uploadFile(image);

    try {
      // Prepare activity data
      const activityData = {
        name: name,
        img: imageUrl,
      };

      // Send activity data to the server
      await axiosInstance.post("/atelier", activityData);
      alert("Activity added successfully!");

      // Clear form
      setName("");
      setImage("");
    } catch (error) {
      console.error("There was an error adding the activity!", error);
    }
  };

  return (
    <div className="mt-32 flex justify-center">
      <form
        onSubmit={handleSubmit}
        className="bg-white p-8 rounded-lg shadow-lg w-full max-w-lg"
      >
        <h2 className="text-2xl font-bold mb-6">Ajouter une Activité</h2>
        <div className="mb-4">
          <label
            className="block text-gray-700 font-semibold mb-2"
            htmlFor="name"
          >
            Nom de l'activité
          </label>
          <input
            type="text"
            id="name"
            className="w-full px-3 py-2 border rounded-lg focus:outline-none focus:ring focus:border-blue-300"
            value={name}
            onChange={(e) => setName(e.target.value)}
            required
          />
        </div>
        <div className="mb-4">
          <label
            className="block text-gray-700 font-semibold mb-2"
            htmlFor="image"
          >
            Image
          </label>
          <input
            type="file"
            id="image"
            className="w-full px-3 py-2 border rounded-lg focus:outline-none focus:ring focus:border-blue-300"
            onChange={(e) => handleFileChange(e)}
            encType="multipart/form-data"
            required
          />
        </div>

        <button
          type="submit"
          className="w-full bg-blue-500 text-white font-semibold py-2 rounded-lg hover:bg-blue-600 transition duration-200"
        >
          Ajouter l'Activité
        </button>
      </form>
    </div>
  );
}

export default AddActivity;
