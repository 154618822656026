import React, { useState, useEffect } from "react";
import FondBlancOffre from "../component/events/FondBlancOffre";
import FondNoirOffre from "../component/events/FondNoirOffre";
import axiosInstance from "../middleware/axiosInstance";

function EvenementScreen() {
  const [events, setEvents] = useState([]);
  const getImageURL = (imageName) =>
    `${process.env.REACT_APP_URL}image/${imageName}`;

  useEffect(() => {
    axiosInstance
      .get("/events")
      .then((response) => {
        setEvents(response.data);
      })
      .catch((error) => console.log(error.message));
  }, []);

  return (
    <div>
      <div className="mb-10"></div>
      {events.length > 0 ? (
        events.map((event, index) => {
          if (index % 2 === 0) {
            return (
              <FondNoirOffre
                idEvent={event._id}
                key={event.id}
                title={event.name}
                imageSrc={getImageURL(event.image)}
                description={event.description}
              />
            );
          } else {
            return (
              <FondBlancOffre
                idEvent={event._id}
                key={event.id}
                id={event.id}
                title={event.name}
                imageSrc={getImageURL(event.image)}
                description={event.description}
              />
            );
          }
        })
      ) : (
        <p>Loading...</p>
      )}
    </div>
  );
}

export default EvenementScreen;
