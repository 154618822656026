import React from "react";
import ContactezNousNoir from "./ContacterNousNoir";

function Rdv() {
  return (
    <>
      <div className="lg:w-[50vw] text-center mx-auto mt-24 w-[75vw] Museo text-4xl mb-16">
        <u>
          Venez prendre rendez-vous pour définir ensemble votre thème et
          découvrir les planches tendances
        </u>
      </div>
      <div className="mb-16">
        <ContactezNousNoir />
      </div>
    </>
  );
}

export default Rdv;
