import React, { useState, useEffect, useRef } from "react";
import axiosInstance from "../../middleware/axiosInstance";
import { Editor } from "@tinymce/tinymce-react";

function AddSectionEvent() {
  const [events, setEvents] = useState([]);
  const [selectedEventId, setSelectedEventId] = useState("");
  const [sectionName, setSectionName] = useState("");
  const [sectionImage, setSectionImage] = useState("");
  const [sectionImageUrl, setSectionImageUrl] = useState("");
  const [sectionTexte, setSectionTexte] = useState("");
  const [selectedImageFile, setSelectedImageFile] = useState(null);
  const editorRef = useRef(null);
  useEffect(() => {
    // Fetch all events to populate the dropdown
    const fetchEvents = async () => {
      try {
        const response = await axiosInstance.get("events");
        setEvents(response.data);
      } catch (error) {
        console.error("There was an error fetching the events!", error);
      }
    };

    fetchEvents();
  }, []);

  const handleFileChange = (event) => {
    const file = event.target.files[0];
    setSelectedImageFile(file);
    setSectionImageUrl(file.name);
  };

  const onEditorChange = function (a, editor) {
    setSectionTexte(editorRef.current.getContent());
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    const uploadFile = async (file) => {
      const formData = new FormData();
      formData.append("file", file);

      const config = {
        headers: { "content-type": "multipart/form-data" },
      };

      try {
        const response = await axiosInstance.post("/upload", formData, config);
        return response.data.filePath;
      } catch (error) {
        console.error("There was an error uploading the file!", error);
        throw error;
      }
    };

    try {
      // Upload section image if file is selected
      uploadFile(selectedImageFile);

      // Prepare section data
      const sectionData = {
        name: sectionName,
        image: sectionImageUrl,
        texte: sectionTexte,
      };

      // Send section data to the server
      await axiosInstance.post(`sections/${selectedEventId}`, sectionData);
      alert("Section added successfully!");

      // Clear form
      setSelectedEventId("");
      setSectionName("");
      setSectionImage("");
      setSectionImageUrl("");
      setSectionTexte("");
      setSelectedImageFile(null);
    } catch (error) {
      console.error("There was an error adding the section!", error);
    }
  };

  return (
    <div className="mt-32 flex justify-center">
      <form
        onSubmit={handleSubmit}
        className="bg-white p-8 rounded-lg shadow-lg w-full max-w-lg"
      >
        <h2 className="text-2xl font-bold mb-6">Ajouter une Section</h2>
        <div className="mb-4">
          <label
            className="block text-gray-700 font-semibold mb-2"
            htmlFor="event"
          >
            Sélectionner un événement
          </label>
          <select
            id="event"
            className="w-full px-3 py-2 border rounded-lg focus:outline-none focus:ring focus:border-blue-300"
            value={selectedEventId}
            onChange={(e) => setSelectedEventId(e.target.value)}
            required
          >
            <option value="">Sélectionner un événement</option>
            {events.map((event) => (
              <option key={event._id} value={event._id}>
                {event.name}
              </option>
            ))}
          </select>
        </div>
        <div className="mb-4">
          <label
            className="block text-gray-700 font-semibold mb-2"
            htmlFor="sectionName"
          >
            Nom de la section
          </label>
          <input
            type="text"
            id="sectionName"
            className="w-full px-3 py-2 border rounded-lg focus:outline-none focus:ring focus:border-blue-300"
            value={sectionName}
            onChange={(e) => setSectionName(e.target.value)}
            required
          />
        </div>
        <div className="mb-4">
          <label
            className="block text-gray-700 font-semibold mb-2"
            htmlFor="sectionImage"
          >
            Image de la section
          </label>
          <input
            type="file"
            id="sectionImage"
            className="w-full px-3 py-2 border rounded-lg focus:outline-none focus:ring focus:border-blue-300"
            onChange={(e) => handleFileChange(e)}
            encType="multipart/form-data"
            required
          />
        </div>
        {/* <div className="mb-4">
          <label
            className="block text-gray-700 font-semibold mb-2"
            htmlFor="sectionTexte"
          >
            Texte de la section
          </label>
          <textarea
            id="sectionTexte"
            className="w-full px-3 py-2 border rounded-lg focus:outline-none focus:ring focus:border-blue-300"
            value={sectionTexte}
            onChange={(e) => setSectionTexte(e.target.value)}
            required
          />
        </div> */}
        <div className="mb-4">
          <label>Texte</label>
          <Editor
            apiKey="qechaaksdccwyq9o7krr71livdg5etn52i5krhlle0oszh9h"
            onInit={(_evt, editor) => (editorRef.current = editor)}
            onEditorChange={onEditorChange}
            init={{
              height: 500,
              menubar: false,
              plugins: [
                "advlist",
                "autolink",
                "lists",
                "link",
                "image",
                "charmap",
                "preview",
                "anchor",
                "searchreplace",
                "visualblocks",
                "code",
                "fullscreen",
                "insertdatetime",
                "media",
                "table",
                "code",
                "help",
                "wordcount",
              ],
              toolbar:
                "undo redo | formatselect | bold italic underline | \
              alignleft aligncenter alignright alignjustify | \
              bullist numlist outdent indent | removeformat | help",
            }}
          />
        </div>
        <button
          type="submit"
          className="w-full bg-blue-500 text-white font-semibold py-2 rounded-lg hover:bg-blue-600 transition duration-200"
        >
          Ajouter la Section
        </button>
      </form>
    </div>
  );
}

export default AddSectionEvent;
