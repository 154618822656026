import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";

import axiosInstance from "../middleware/axiosInstance";
import ContactezNousNoir from "../component/ContacterNousNoir";
import OffreAtelier from "../component/atelier/OffreAtelier";
import { Helmet } from "react-helmet";

function ActiviteScreen({ name: name2 }) {
  const { name: nameFromParams } = useParams();
  const name = name2 || nameFromParams;

  const [activite, setActivite] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  const getImageURL = (imageName) =>
    `${process.env.REACT_APP_URL}image/${imageName}`;

  useEffect(() => {
    axiosInstance
      .get(`atelier/name/${name}`)
      .then((response) => {
        setActivite(response.data);
        setLoading(false);
      })
      .catch((error) => {
        setError(error.message);
        setLoading(false);
      });
  }, [name]);

  if (loading) {
    return (
      <div className="flex items-center justify-center min-h-screen">
        <div className="text-2xl">Chargement...</div>
      </div>
    );
  }

  // Vérifie si activite est null ou undefined
  if (!activite) {
    return (
      <div className="flex items-center justify-center min-h-screen">
        <div className="text-2xl">Aucune activité trouvée pour ce nom.</div>
      </div>
    );
  }

  const rows = [];
  for (let i = 0; i < activite.sectionAtelier_id.length; i += 2) {
    rows.push(activite.sectionAtelier_id.slice(i, i + 2));
  }

  return (
    <div className="mt-20 ">
      <Helmet>
        <title>{activite.name} - Merveilleux Moments</title>
        <meta
          name="description"
          content="Vous chercher de la decoration DIY pour votre anniversaire ou votre mariage ou un evenement solenel comme une communion ?"
        />
        <meta
          name="keywords"
          content="DIY, Atelier, atelier DIY, événements, décoration, mariage, anniversaire, Val-de-Marne, Maryline, Merveilleux Moments"
        />
        <meta property="og:title" content="location - Merveilleux Moments" />
        <meta
          property="og:description"
          content="Vous chercher a louer de la decoration pour votre anniversaire ou votre mariage ou un evenement solenel comme une communion ?"
        />
        <meta
          property="og:image"
          content="https://votre-site.com/images/og-contact-image.jpg"
        />
        <meta property="og:url" content="https://votre-site.com/contact" />
        <meta property="og:type" content="website" />
        <meta name="twitter:card" content="summary_large_image" />
        <meta
          name="twitter:title"
          content="Contactez-nous - Merveilleux Moments"
        />
        <meta
          name="twitter:description"
          content="Vous chercher a louer de la decoration pour votre anniversaire ou votre mariage ou un evenement solenel comme une communion ?"
        />
        <meta
          name="twitter:image"
          content="https://votre-site.com/images/twitter-contact-image.jpg"
        />
        <meta name="twitter:site" content="@votre_compte" />
      </Helmet>
      <div className="lg:flex lg:justify-evenly items-center">
        <h1 className="lg:text-8xl text-3xl md:text-6xl text-center my-8 MuseoBold py-6">
          {activite.name}
        </h1>
        <img
          src={getImageURL(activite.img)}
          alt={`${activite.name} image `}
          className="rounded-[90px] sm:h-[600px] h-[400px] mx-auto lg:mx-0 object-cover my-16"
        />
      </div>

      <div className="flex flex-col items-center">
        {rows.map((row, rowIndex) => (
          <div
            key={rowIndex}
            className="flex flex-col lg:flex-row lg:space-x-4 mb-4 w-full items-center justify-center"
          >
            {row.map((section) => (
              <OffreAtelier
                key={section.id}
                title={section.title}
                sous_titre={section.sous_titre}
                content={section.texte}
                prix={section.prix}
              />
            ))}
          </div>
        ))}
      </div>
      <div className="lg:w-[50vw] text-center mx-auto mt-24 w-[75vw] Museo md:text-4xl text-2xl mb-16">
        <u>Pour plus d'informations ou pour réserver dès aujourd'hui</u>
      </div>
      <div className="mb-16">
        <ContactezNousNoir />
      </div>
    </div>
  );
}

export default ActiviteScreen;
