import React, { useState, useEffect } from "react";
import axiosInstance from "../middleware/axiosInstance";
import ActiviteScreen from "./ActiviteScreen";

function AllActivite() {
  const [locations, setLocations] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    axiosInstance
      .get(`/atelier`)
      .then((response) => {
        setLocations(response.data);
        setLoading(false);
      })
      .catch((error) => {
        setError(error.message);
        setLoading(false);
      });
  }, []);

  if (loading) {
    return <div>Loading...</div>;
  }

  if (error) {
    return <div>Error: {error}</div>;
  }

  if (!locations.length) {
    return <div>No location data available</div>;
  }

  return (
    <>
      {locations.map((atelier, index) => (
        <div key={index}>
          <ActiviteScreen name={atelier.name} />
        </div>
      ))}
    </>
  );
}

export default AllActivite;
