import React, { useState, useEffect } from "react";
import axiosInstance from "../../middleware/axiosInstance";

const DeleteSectionLocation = () => {
  const [locations, setLocations] = useState([]);
  const [sections, setSections] = useState([]);
  const [selectedLocationId, setSelectedLocationId] = useState("");
  const [selectedSectionId, setSelectedSectionId] = useState("");

  // Fetch all locations on component mount
  useEffect(() => {
    const fetchLocations = async () => {
      try {
        const response = await axiosInstance.get("/location");
        setLocations(response.data);
      } catch (error) {
        console.error("Error fetching locations:", error);
      }
    };

    fetchLocations();
  }, []);

  // Fetch sections when selectedLocationId changes
  useEffect(() => {
    const fetchSections = async () => {
      if (selectedLocationId) {
        const selectedLocation = locations.find(
          (location) => location._id === selectedLocationId
        );
        if (selectedLocation) {
          setSections(selectedLocation.sectionLocation_id);
        }
      } else {
        setSections([]);
      }
    };

    fetchSections();
  }, [selectedLocationId, locations]);

  const handleDelete = async () => {
    if (!selectedSectionId) {
      alert("Please select a section to delete.");
      return;
    }

    try {
      await axiosInstance.delete(`/sectionsLocation/${selectedSectionId}`);
      alert("Section deleted successfully!");
      setSections(
        sections.filter((section) => section._id !== selectedSectionId)
      );
      setSelectedSectionId("");
    } catch (error) {
      console.error("Error deleting section:", error);
    }
  };

  return (
    <div className="mt-32 flex justify-center">
      <div className="bg-white p-8 rounded-lg shadow-lg w-full max-w-lg">
        <h2 className="text-2xl font-bold mb-6">
          Supprimer une section de location
        </h2>

        <div className="mb-4">
          <label
            className="block text-gray-700 font-semibold mb-2"
            htmlFor="selectLocation"
          >
            Sélectionner une location
          </label>
          <select
            id="selectLocation"
            className="w-full px-3 py-2 border rounded-lg focus:outline-none focus:ring focus:border-blue-300"
            value={selectedLocationId}
            onChange={(e) => setSelectedLocationId(e.target.value)}
            required
          >
            <option value="">Sélectionner une location</option>
            {locations.map((location) => (
              <option key={location._id} value={location._id}>
                {location.name}
              </option>
            ))}
          </select>
        </div>

        {selectedLocationId && (
          <div className="mb-4">
            <label
              className="block text-gray-700 font-semibold mb-2"
              htmlFor="selectSection"
            >
              Sélectionner une section
            </label>
            <select
              id="selectSection"
              className="w-full px-3 py-2 border rounded-lg focus:outline-none focus:ring focus:border-blue-300"
              value={selectedSectionId}
              onChange={(e) => setSelectedSectionId(e.target.value)}
              required
            >
              <option value="">Sélectionner une section</option>
              {sections.map((section) => (
                <option key={section._id} value={section._id}>
                  {section.title}
                </option>
              ))}
            </select>
          </div>
        )}

        {selectedSectionId && (
          <button
            onClick={handleDelete}
            className="w-full bg-red-500 text-white font-semibold py-2 rounded-lg hover:bg-red-600 transition duration-200"
          >
            Supprimer la section
          </button>
        )}
      </div>
    </div>
  );
};

export default DeleteSectionLocation;
