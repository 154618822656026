import React, { useState, useEffect, useRef, useCallback } from "react";
import axiosInstance from "../../middleware/axiosInstance";
import { Editor } from "@tinymce/tinymce-react";

const EditContact = () => {
  const [contacts, setContacts] = useState([]);
  const [selectedContactId, setSelectedContactId] = useState("");
  const [contactData, setContactData] = useState(null);
  const [ZI, setZI] = useState("");
  const [text1, setText1] = useState("");
  const [text2, setText2] = useState("");
  const [text3, setText3] = useState("");
  const [maryline, setMaryline] = useState("");
  const [disponibilite, setDisponibilite] = useState("");
  const editorRefText3 = useRef(null);
  const editorRefMaryline = useRef(null);
  const editorRefDisponibilite = useRef(null);

  // Fetch all contacts on component mount
  useEffect(() => {
    axiosInstance
      .get("/contacts")
      .then((response) => {
        setContacts(response.data);
      })
      .catch((error) => {
        console.error("Error fetching contacts:", error);
      });
  }, []);

  // Fetch selected contact data when selectedContactId changes
  useEffect(() => {
    if (selectedContactId) {
      axiosInstance
        .get(`/contacts/${selectedContactId}`)
        .then((response) => {
          const contactData = response.data;
          setContactData(contactData);
          setZI(contactData.ZI);
          setText1(contactData.text1);
          setText2(contactData.text2);
          setText3(contactData.text3);
          setMaryline(contactData.maryline);
          setDisponibilite(contactData.disponibilite);
        })
        .catch((error) => {
          console.error("Error fetching contact:", error);
        });
    }
  }, [selectedContactId]);

  const onEditorChangeText3 = useCallback((content) => {
    setText3(content);
  }, []);

  const onEditorChangeMaryline = useCallback((content) => {
    setMaryline(content);
  }, []);

  const onEditorChangeDisponibilite = useCallback((content) => {
    setDisponibilite(content);
  }, []);

  const handleSubmit = async (e) => {
    e.preventDefault();

    try {
      // Prepare contact data
      const updatedContactData = {
        ZI,
        text1,
        text2,
        text3,
        maryline,
        disponibilite,
      };

      // Send contact data to the server
      await axiosInstance.put(
        `/contacts/${selectedContactId}`,
        updatedContactData
      );
      alert("Contact updated successfully!");
    } catch (error) {
      console.error("There was an error updating the contact!", error);
    }
  };

  return (
    <div className="mt-32 flex justify-center">
      <form
        onSubmit={handleSubmit}
        className="bg-white p-8 rounded-lg shadow-lg w-full max-w-lg"
      >
        <h2 className="text-2xl font-bold mb-6">Modifier le Contact</h2>

        <div className="mb-4">
          <label
            className="block text-gray-700 font-semibold mb-2"
            htmlFor="selectContact"
          >
            Sélectionner un contact à modifier
          </label>
          <select
            id="selectContact"
            className="w-full px-3 py-2 border rounded-lg focus:outline-none focus:ring focus:border-blue-300"
            value={selectedContactId}
            onChange={(e) => setSelectedContactId(e.target.value)}
            required
          >
            <option value="">Sélectionner un contact</option>
            {contacts.map((contact) => (
              <option key={contact._id} value={contact._id}>
                {contact.ZI}
              </option>
            ))}
          </select>
        </div>

        {contactData && (
          <>
            <div className="mb-4">
              <label
                className="block text-gray-700 font-semibold mb-2"
                htmlFor="ZI"
              >
                ZI
              </label>
              <input
                type="text"
                id="ZI"
                className="w-full px-3 py-2 border rounded-lg focus:outline-none focus:ring focus:border-blue-300"
                value={ZI}
                onChange={(e) => setZI(e.target.value)}
                required
              />
            </div>
            <div className="mb-4">
              <label
                className="block text-gray-700 font-semibold mb-2"
                htmlFor="text1"
              >
                Texte 1
              </label>
              <input
                type="text"
                id="text1"
                className="w-full px-3 py-2 border rounded-lg focus:outline-none focus:ring focus:border-blue-300"
                value={text1}
                onChange={(e) => setText1(e.target.value)}
                required
              />
            </div>
            <div className="mb-4">
              <label
                className="block text-gray-700 font-semibold mb-2"
                htmlFor="text2"
              >
                Texte 2
              </label>
              <input
                type="text"
                id="text2"
                className="w-full px-3 py-2 border rounded-lg focus:outline-none focus:ring focus:border-blue-300"
                value={text2}
                onChange={(e) => setText2(e.target.value)}
                required
              />
            </div>
            <div className="mb-4">
              <label className="text-gray-700 font-semibold mb-2">
                Texte 3
              </label>
              <Editor
                apiKey="qechaaksdccwyq9o7krr71livdg5etn52i5krhlle0oszh9h"
                onInit={(_evt, editor) => (editorRefText3.current = editor)}
                value={text3}
                onEditorChange={onEditorChangeText3}
                init={{
                  height: 200,
                  menubar: false,
                  plugins: [
                    "advlist",
                    "autolink",
                    "lists",
                    "link",
                    "image",
                    "charmap",
                    "preview",
                    "anchor",
                    "searchreplace",
                    "visualblocks",
                    "code",
                    "fullscreen",
                    "insertdatetime",
                    "media",
                    "table",
                    "code",
                    "help",
                    "wordcount",
                  ],
                  toolbar:
                    "undo redo | formatselect | bold italic underline | \
                alignleft aligncenter alignright alignjustify | \
                bullist numlist outdent indent | removeformat | help",
                }}
              />
            </div>
            <div className="mb-4">
              <label className="text-gray-700 font-semibold mb-2">
                Maryline
              </label>
              <Editor
                apiKey="qechaaksdccwyq9o7krr71livdg5etn52i5krhlle0oszh9h"
                onInit={(_evt, editor) => (editorRefMaryline.current = editor)}
                value={maryline}
                onEditorChange={onEditorChangeMaryline}
                init={{
                  height: 200,
                  menubar: false,
                  plugins: [
                    "advlist",
                    "autolink",
                    "lists",
                    "link",
                    "image",
                    "charmap",
                    "preview",
                    "anchor",
                    "searchreplace",
                    "visualblocks",
                    "code",
                    "fullscreen",
                    "insertdatetime",
                    "media",
                    "table",
                    "code",
                    "help",
                    "wordcount",
                  ],
                  toolbar:
                    "undo redo | formatselect | bold italic underline | \
                alignleft aligncenter alignright alignjustify | \
                bullist numlist outdent indent | removeformat | help",
                }}
              />
            </div>
            <div className="mb-4">
              <label className="text-gray-700 font-semibold mb-2">
                Disponibilité
              </label>
              <Editor
                apiKey="qechaaksdccwyq9o7krr71livdg5etn52i5krhlle0oszh9h"
                onInit={(_evt, editor) =>
                  (editorRefDisponibilite.current = editor)
                }
                value={disponibilite}
                onEditorChange={onEditorChangeDisponibilite}
                init={{
                  height: 200,
                  menubar: false,
                  plugins: [
                    "advlist",
                    "autolink",
                    "lists",
                    "link",
                    "image",
                    "charmap",
                    "preview",
                    "anchor",
                    "searchreplace",
                    "visualblocks",
                    "code",
                    "fullscreen",
                    "insertdatetime",
                    "media",
                    "table",
                    "code",
                    "help",
                    "wordcount",
                  ],
                  toolbar:
                    "undo redo | formatselect | bold italic underline | \
                alignleft aligncenter alignright alignjustify | \
                bullist numlist outdent indent | removeformat | help",
                }}
              />
            </div>
            <button
              type="submit"
              className="w-full bg-blue-500 text-white font-semibold py-2 rounded-lg hover:bg-blue-600 transition duration-200"
            >
              Modifier le Contact
            </button>
          </>
        )}
      </form>
    </div>
  );
};

export default EditContact;
