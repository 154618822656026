import React from "react";
import ImageHomePage from "../component/home/ImageHomePage";
import BlockOffres from "../component/home/BlockOffres/BlockOffres";
import Maryline from "../component/home/Maryline";
import "./HomeScreen.css";
import { Helmet } from "react-helmet";

function HomeScreen() {
  return (
    <div className="mt-10">
      <Helmet>
        <title>Merveilleux Moments - Page d'accueil</title>
        <meta
          name="description"
          content="Découvrez comment Merveilleux Moments crée des souvenirs inoubliables grâce à nos événements magiques."
        />
        <meta
          name="keywords"
          content="événements, décoration, mariage, anniversaire, Val-de-Marne, Maryline, Merveilleux Moments"
        />
        <meta
          property="og:title"
          content="Merveilleux Moments - Page d'accueil"
        />
        <meta
          property="og:description"
          content="Découvrez comment Merveilleux Moments crée des souvenirs inoubliables grâce à nos événements magiques."
        />
        <meta
          property="og:image"
          content="https://votre-site.com/images/og-image.jpg"
        />
        <meta property="og:url" content="https://merveilleuxmoment.fr" />
        <meta property="og:type" content="website" />
      </Helmet>
      <ImageHomePage />
      <BlockOffres />
      <Maryline />
    </div>
  );
}

export default HomeScreen;
