import React, { useState, useEffect } from "react";
import axiosInstance from "../../middleware/axiosInstance";

const DeleteActivity = () => {
  const [activities, setActivities] = useState([]);
  const [selectedActivityId, setSelectedActivityId] = useState("");

  // Fetch all activities on component mount
  useEffect(() => {
    const fetchActivities = async () => {
      try {
        const response = await axiosInstance.get("/atelier");
        setActivities(response.data);
      } catch (error) {
        console.error("Error fetching activities:", error);
      }
    };

    fetchActivities();
  }, []);

  const handleDelete = async () => {
    if (!selectedActivityId) {
      alert("Please select an activity to delete.");
      return;
    }

    try {
      await axiosInstance.delete(`/atelier/${selectedActivityId}`);
      alert("Activity deleted successfully!");
      setActivities(
        activities.filter((activity) => activity._id !== selectedActivityId)
      );
      setSelectedActivityId("");
    } catch (error) {
      console.error("Error deleting activity:", error);
    }
  };

  return (
    <div className="mt-32 flex justify-center">
      <div className="bg-white p-8 rounded-lg shadow-lg w-full max-w-lg">
        <h2 className="text-2xl font-bold mb-6">Supprimer une activité</h2>

        <div className="mb-4">
          <label
            className="block text-gray-700 font-semibold mb-2"
            htmlFor="selectActivity"
          >
            Sélectionner une activité
          </label>
          <select
            id="selectActivity"
            className="w-full px-3 py-2 border rounded-lg focus:outline-none focus:ring focus:border-blue-300"
            value={selectedActivityId}
            onChange={(e) => setSelectedActivityId(e.target.value)}
            required
          >
            <option value="">Sélectionner une activité</option>
            {activities.map((activity) => (
              <option key={activity._id} value={activity._id}>
                {activity.name}
              </option>
            ))}
          </select>
        </div>

        <button
          onClick={handleDelete}
          className="w-full bg-red-500 text-white font-semibold py-2 rounded-lg hover:bg-red-600 transition duration-200"
        >
          Supprimer l'activité
        </button>
      </div>
    </div>
  );
};

export default DeleteActivity;
