import React, { useState, useEffect, useRef, useCallback } from "react";
import axiosInstance from "../../middleware/axiosInstance";
import { Editor } from "@tinymce/tinymce-react";

const EditEvent = () => {
  const [events, setEvents] = useState([]);
  const [selectedEventId, setSelectedEventId] = useState("");
  const [eventData, setEventData] = useState(null);
  const [name, setName] = useState("");
  const [image, setImage] = useState(null);
  const [imageUrl, setImageUrl] = useState("");
  const [imgBandeau, setImgBandeau] = useState(null);
  const [imageUrlBandeau, setImageUrlBandeau] = useState("");
  const [description, setDescription] = useState("");
  const [texteFrais, setTexteFrais] = useState("");
  const editorRef = useRef(null);
  const editorRef2 = useRef(null);

  // Fetch all events on component mount
  useEffect(() => {
    axiosInstance
      .get("/events")
      .then((response) => {
        setEvents(response.data);
      })
      .catch((error) => {
        console.error("Error fetching events:", error);
      });
  }, []);

  // Fetch selected event data when selectedEventId changes
  useEffect(() => {
    if (selectedEventId) {
      axiosInstance
        .get(`/events/${selectedEventId}`)
        .then((response) => {
          const eventData = response.data;
          setEventData(eventData);
          setName(eventData.name);
          setImageUrl(eventData.image);
          setImageUrlBandeau(eventData.img_bandeau);
          setDescription(eventData.description);
          setTexteFrais(eventData.texte_frais);
        })
        .catch((error) => {
          console.error("Error fetching event:", error);
        });
    }
  }, [selectedEventId]);

  const handleFileChange = (event) => {
    const file = event.target.files[0];
    setImage(file);
    setImageUrl(file.name);
  };

  const handleFileChangeBandeau = (event) => {
    const file = event.target.files[0];
    setImgBandeau(file);
    setImageUrlBandeau(file.name);
  };

  const onEditorChange = useCallback((content) => {
    setDescription(content);
  }, []);

  const onEditorChange2 = useCallback((content) => {
    setTexteFrais(content);
  }, []);

  const handleSubmit = async (e) => {
    e.preventDefault();

    const uploadFile = async (file) => {
      const formData = new FormData();
      formData.append("file", file);

      const config = {
        headers: { "content-type": "multipart/form-data" },
      };

      try {
        const response = await axiosInstance.post("/upload", formData, config);
        return response.data.filePath;
      } catch (error) {
        console.error("There was an error uploading the file!", error);
        throw error;
      }
    };

    let uploadedImageUrl = imageUrl;
    let uploadedImageUrlBandeau = imageUrlBandeau;

    if (image) {
      uploadedImageUrl = await uploadFile(image);
    }

    if (imgBandeau) {
      uploadedImageUrlBandeau = await uploadFile(imgBandeau);
    }

    try {
      // Prepare event data
      const updatedEventData = {
        name,
        image: uploadedImageUrl,
        description,
        texte_frais: texteFrais,
        img_bandeau: uploadedImageUrlBandeau,
      };

      // Send event data to the server
      await axiosInstance.put(`/events/${selectedEventId}`, updatedEventData);
      alert("Event updated successfully!");
    } catch (error) {
      console.error("There was an error updating the event!", error);
    }
  };

  return (
    <div className="mt-32 flex justify-center">
      <form
        onSubmit={handleSubmit}
        className="bg-white p-8 rounded-lg shadow-lg w-full max-w-lg"
      >
        <h2 className="text-2xl font-bold mb-6">Modifier l'Événement</h2>

        <div className="mb-4">
          <label
            className="block text-gray-700 font-semibold mb-2"
            htmlFor="selectEvent"
          >
            Sélectionner un événement à modifier
          </label>
          <select
            id="selectEvent"
            className="w-full px-3 py-2 border rounded-lg focus:outline-none focus:ring focus:border-blue-300"
            value={selectedEventId}
            onChange={(e) => setSelectedEventId(e.target.value)}
            required
          >
            <option value="">Sélectionner un événement</option>
            {events.map((event) => (
              <option key={event._id} value={event._id}>
                {event.name}
              </option>
            ))}
          </select>
        </div>

        {eventData && (
          <>
            <div className="mb-4">
              <label
                className="block text-gray-700 font-semibold mb-2"
                htmlFor="name"
              >
                Nom de l'événement
              </label>
              <input
                type="text"
                id="name"
                className="w-full px-3 py-2 border rounded-lg focus:outline-none focus:ring focus:border-blue-300"
                value={name}
                onChange={(e) => setName(e.target.value)}
                required
              />
            </div>
            <div className="mb-4">
              <label
                className="block text-gray-700 font-semibold mb-2"
                htmlFor="image"
              >
                Image
              </label>
              <input
                type="file"
                id="image"
                className="w-full px-3 py-2 border rounded-lg focus:outline-none focus:ring focus:border-blue-300"
                onChange={handleFileChange}
                encType="multipart/form-data"
              />
            </div>
            <div className="mb-4">
              <label
                className="block text-gray-700 font-semibold mb-2"
                htmlFor="imgBandeau"
              >
                Image Bandeau
              </label>
              <input
                type="file"
                id="imgBandeau"
                className="w-full px-3 py-2 border rounded-lg focus:outline-none focus:ring focus:border-blue-300"
                onChange={handleFileChangeBandeau}
                encType="multipart/form-data"
              />
            </div>

            <div className="mb-4">
              <label className="text-gray-700 font-semibold mb-2">
                Description
              </label>
              <Editor
                apiKey="qechaaksdccwyq9o7krr71livdg5etn52i5krhlle0oszh9h"
                onInit={(_evt, editor) => (editorRef.current = editor)}
                value={description}
                onEditorChange={onEditorChange}
                init={{
                  height: 500,
                  menubar: false,
                  plugins: [
                    "advlist",
                    "autolink",
                    "lists",
                    "link",
                    "image",
                    "charmap",
                    "preview",
                    "anchor",
                    "searchreplace",
                    "visualblocks",
                    "code",
                    "fullscreen",
                    "insertdatetime",
                    "media",
                    "table",
                    "code",
                    "help",
                    "wordcount",
                  ],
                  toolbar:
                    "undo redo | formatselect | bold italic underline | \
              alignleft aligncenter alignright alignjustify | \
              bullist numlist outdent indent | removeformat | help",
                }}
              />
            </div>
            <div className="mb-4">
              <label className="text-gray-700 font-semibold mb-2">
                Texte frais d'installation etc
              </label>
              <Editor
                apiKey="qechaaksdccwyq9o7krr71livdg5etn52i5krhlle0oszh9h"
                onInit={(_evt, editor) => (editorRef2.current = editor)}
                onEditorChange={onEditorChange2}
                value={texteFrais}
                init={{
                  height: 200,
                  menubar: false,
                  plugins: [
                    "advlist",
                    "autolink",
                    "lists",
                    "link",
                    "image",
                    "charmap",
                    "preview",
                    "anchor",
                    "searchreplace",
                    "visualblocks",
                    "code",
                    "fullscreen",
                    "insertdatetime",
                    "media",
                    "table",
                    "code",
                    "help",
                    "wordcount",
                  ],
                  toolbar:
                    "undo redo | formatselect | bold italic underline | \
              alignleft aligncenter alignright alignjustify | \
              bullist numlist outdent indent | removeformat | help",
                }}
              />
            </div>
            <button
              type="submit"
              className="w-full bg-blue-500 text-white font-semibold py-2 rounded-lg hover:bg-blue-600 transition duration-200"
            >
              Modifier l'Événement
            </button>
          </>
        )}
      </form>
    </div>
  );
};

export default EditEvent;
