import React from "react";

function ImageHomePage() {
  return (
    <div className="relative">
      <img className="w-screen" src="hb.png" alt="image happy birthday" />
      <div className="absolute top-2/3 left-1/2 transform -translate-x-1/2 text-center text-white">
        <p className="Museo lg:text-6xl md:text-4xl text-2xl">
          Créer des souvenirs inoubliables
        </p>
      </div>
    </div>
  );
}

export default ImageHomePage;
