import React, { useState, useEffect } from "react";
import axiosInstance from "../middleware/axiosInstance";
import OffreMariage from "../component/mariage/OffreMariage";
import Rdv from "../component/Rdv";

function MariageScreen() {
  const [mariage, setMariage] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  const getImageURL = (imageName) =>
    `${process.env.REACT_APP_URL}image/${imageName}`;

  useEffect(() => {
    axiosInstance
      .get(`mariage`)
      .then((response) => {
        setMariage(response.data[0]);
        setLoading(false);
      })
      .catch((error) => {
        setError(error.message);
        setLoading(false);
      });
  }, []);

  if (loading) {
    return (
      <div className="flex items-center justify-center min-h-screen">
        <div className="text-2xl">Chargement...</div>
      </div>
    );
  }

  if (error) {
    return (
      <div className="flex items-center justify-center min-h-screen">
        <div className="text-2xl text-red-600">Erreur: {error}</div>
      </div>
    );
  }

  const rows = [];
  for (let i = 0; i < mariage.sectionMariage_id.length; i += 2) {
    rows.push(mariage.sectionMariage_id.slice(i, i + 2));
  }

  return (
    <div>
      <div className="mb-16 bg-black"></div>
      <div className="text-center">
        <div className="bg-black">
          <h1 className="text-6xl text-white my-8 Museo py-6">
            {mariage.name}
          </h1>
          <img
            src={getImageURL(mariage.img_bandeau)}
            alt={`${mariage.name} banner`}
            className="w-full h-[600px] object-cover text-white mb-16"
          />
        </div>

        <div className="flex items-center justify-center mb-10">
          <div className="h-1 w-52 bg-black"></div>
          <div className="px-4">
            <div className="text-center text-black MohaveBold text-5xl">
              Découvrez nos offres
            </div>
          </div>
          <div className="h-1 w-52 bg-black"></div>
        </div>

        <div className="flex flex-col items-center">
          {rows.map((row, rowIndex) => (
            <div
              key={rowIndex}
              className="flex flex-col lg:flex-row lg:space-x-4 mb-4 w-full items-center justify-center"
            >
              {row.map((section) => (
                <OffreMariage
                  key={section.id}
                  title={section.title}
                  content={section.texte}
                  texte_rdv={section.texte_rdv}
                  texte_frais={section.texte_frais}
                />
              ))}
            </div>
          ))}
        </div>

        <Rdv />
      </div>
    </div>
  );
}

export default MariageScreen;
