import React, { useState, useEffect, useRef, useCallback } from "react";
import axiosInstance from "../../middleware/axiosInstance";
import { Editor } from "@tinymce/tinymce-react";

const EditSectionActivity = () => {
  const [activities, setActivities] = useState([]);
  const [sections, setSections] = useState([]);
  const [selectedActivityId, setSelectedActivityId] = useState("");
  const [selectedSectionId, setSelectedSectionId] = useState("");
  const [sectionData, setSectionData] = useState(null);
  const [sectionTitle, setSectionTitle] = useState("");
  const [sectionSousTitre, setSectionSousTitre] = useState("");
  const [sectionPrix, setSectionPrix] = useState("");
  const [sectionTexte, setSectionTexte] = useState("");
  const editorRef = useRef(null);

  useEffect(() => {
    const fetchActivities = async () => {
      try {
        const response = await axiosInstance.get("/atelier");
        setActivities(response.data);
      } catch (error) {
        console.error("There was an error fetching the activities!", error);
      }
    };

    fetchActivities();
  }, []);

  const handleActivityChange = (event) => {
    setSelectedActivityId(event.target.value);
    setSelectedSectionId("");
    setSectionData(null);
    if (event.target.value) {
      const selectedActivity = activities.find(
        (a) => a._id === event.target.value
      );
      setSections(selectedActivity.sectionAtelier_id);
    } else {
      setSections([]);
    }
  };

  const handleSectionChange = (event) => {
    setSelectedSectionId(event.target.value);
    const section = sections.find(
      (section) => section._id === event.target.value
    );
    if (section) {
      setSectionData(section);
      setSectionTitle(section.title);
      setSectionSousTitre(section.sous_titre);
      setSectionPrix(section.prix);
      setSectionTexte(section.texte);
    }
  };

  const onEditorChange = useCallback((content) => {
    setSectionTexte(content);
  }, []);

  const handleSubmit = async (e) => {
    e.preventDefault();

    try {
      const updatedSectionData = {
        title: sectionTitle,
        sous_titre: sectionSousTitre,
        prix: sectionPrix,
        texte: sectionTexte,
      };

      await axiosInstance.put(
        `/sectionsAtelier/${selectedSectionId}`,
        updatedSectionData
      );
      alert("Section updated successfully!");
    } catch (error) {
      console.error("There was an error updating the section!", error);
    }
  };

  return (
    <div className="mt-48 flex justify-center mb-32">
      <form
        onSubmit={handleSubmit}
        className="bg-white p-8 rounded-lg shadow-lg w-full max-w-lg"
      >
        <h2 className="text-2xl font-bold mb-6">Modifier une Section</h2>
        <div className="mb-4">
          <label
            className="block text-gray-700 font-semibold mb-2"
            htmlFor="activity"
          >
            Sélectionner une activité
          </label>
          <select
            id="activity"
            className="w-full px-3 py-2 border rounded-lg focus:outline-none focus:ring focus:border-blue-300"
            value={selectedActivityId}
            onChange={handleActivityChange}
            required
          >
            <option value="">Sélectionner une activité</option>
            {activities.map((activity) => (
              <option key={activity._id} value={activity._id}>
                {activity.name}
              </option>
            ))}
          </select>
        </div>
        {selectedActivityId && (
          <div className="mb-4">
            <label
              className="block text-gray-700 font-semibold mb-2"
              htmlFor="section"
            >
              Sélectionner une section
            </label>
            <select
              id="section"
              className="w-full px-3 py-2 border rounded-lg focus:outline-none focus:ring focus:border-blue-300"
              value={selectedSectionId}
              onChange={handleSectionChange}
              required
            >
              <option value="">Sélectionner une section</option>
              {sections.map((section) => (
                <option key={section._id} value={section._id}>
                  {section.title}
                </option>
              ))}
            </select>
          </div>
        )}
        {sectionData && (
          <>
            <div className="mb-4">
              <label
                className="block text-gray-700 font-semibold mb-2"
                htmlFor="sectionTitle"
              >
                Titre de la section
              </label>
              <input
                type="text"
                id="sectionTitle"
                className="w-full px-3 py-2 border rounded-lg focus:outline-none focus:ring focus:border-blue-300"
                value={sectionTitle}
                onChange={(e) => setSectionTitle(e.target.value)}
                required
              />
            </div>
            <div className="mb-4">
              <label
                className="block text-gray-700 font-semibold mb-2"
                htmlFor="sectionSousTitre"
              >
                Sous-titre de la section
              </label>
              <input
                type="text"
                id="sectionSousTitre"
                className="w-full px-3 py-2 border rounded-lg focus:outline-none focus:ring focus:border-blue-300"
                value={sectionSousTitre}
                onChange={(e) => setSectionSousTitre(e.target.value)}
                required
              />
            </div>
            <div className="mb-4">
              <label
                className="block text-gray-700 font-semibold mb-2"
                htmlFor="sectionPrix"
              >
                Prix de la section
              </label>
              <input
                type="text"
                id="sectionPrix"
                className="w-full px-3 py-2 border rounded-lg focus:outline-none focus:ring focus:border-blue-300"
                value={sectionPrix}
                onChange={(e) => setSectionPrix(e.target.value)}
                required
              />
            </div>
            <div className="mb-4">
              <label className="block text-gray-700 font-semibold mb-2">
                Texte
              </label>
              <Editor
                apiKey="qechaaksdccwyq9o7krr71livdg5etn52i5krhlle0oszh9h"
                onInit={(_evt, editor) => (editorRef.current = editor)}
                value={sectionTexte}
                onEditorChange={onEditorChange}
                init={{
                  height: 500,
                  menubar: false,
                  plugins: [
                    "advlist",
                    "autolink",
                    "lists",
                    "link",
                    "image",
                    "charmap",
                    "preview",
                    "anchor",
                    "searchreplace",
                    "visualblocks",
                    "code",
                    "fullscreen",
                    "insertdatetime",
                    "media",
                    "table",
                    "code",
                    "help",
                    "wordcount",
                  ],
                  toolbar:
                    "undo redo | formatselect | bold italic underline | alignleft aligncenter alignright alignjustify | bullist numlist outdent indent | removeformat | help",
                }}
              />
            </div>
            <button
              type="submit"
              className="w-full bg-blue-500 text-white font-semibold py-2 rounded-lg hover:bg-blue-600 transition duration-200"
            >
              Modifier la Section
            </button>
          </>
        )}
      </form>
    </div>
  );
};

export default EditSectionActivity;
