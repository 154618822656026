import React, { useState, useEffect } from "react";
import axiosInstance from "../../middleware/axiosInstance";

const DeleteSectionActivity = () => {
  const [activities, setActivities] = useState([]);
  const [sections, setSections] = useState([]);
  const [selectedActivityId, setSelectedActivityId] = useState("");
  const [selectedSectionId, setSelectedSectionId] = useState("");

  // Fetch all activities on component mount
  useEffect(() => {
    const fetchActivities = async () => {
      try {
        const response = await axiosInstance.get("/atelier");
        setActivities(response.data);
      } catch (error) {
        console.error("Error fetching activities:", error);
      }
    };

    fetchActivities();
  }, []);

  // Fetch sections when selectedActivityId changes
  useEffect(() => {
    const fetchSections = async () => {
      if (selectedActivityId) {
        const selectedActivity = activities.find(
          (activity) => activity._id === selectedActivityId
        );
        if (selectedActivity) {
          setSections(selectedActivity.sectionAtelier_id);
        }
      } else {
        setSections([]);
      }
    };

    fetchSections();
  }, [selectedActivityId, activities]);

  const handleDelete = async () => {
    if (!selectedSectionId) {
      alert("Please select a section to delete.");
      return;
    }

    try {
      await axiosInstance.delete(`/sectionsAtelier/${selectedSectionId}`);
      alert("Section deleted successfully!");
      setSections(
        sections.filter((section) => section._id !== selectedSectionId)
      );
      setSelectedSectionId("");
    } catch (error) {
      console.error("Error deleting section:", error);
    }
  };

  return (
    <div className="mt-32 flex justify-center">
      <div className="bg-white p-8 rounded-lg shadow-lg w-full max-w-lg">
        <h2 className="text-2xl font-bold mb-6">
          Supprimer une section d'activité
        </h2>

        <div className="mb-4">
          <label
            className="block text-gray-700 font-semibold mb-2"
            htmlFor="selectActivity"
          >
            Sélectionner une activité
          </label>
          <select
            id="selectActivity"
            className="w-full px-3 py-2 border rounded-lg focus:outline-none focus:ring focus:border-blue-300"
            value={selectedActivityId}
            onChange={(e) => setSelectedActivityId(e.target.value)}
            required
          >
            <option value="">Sélectionner une activité</option>
            {activities.map((activity) => (
              <option key={activity._id} value={activity._id}>
                {activity.name}
              </option>
            ))}
          </select>
        </div>

        {selectedActivityId && (
          <div className="mb-4">
            <label
              className="block text-gray-700 font-semibold mb-2"
              htmlFor="selectSection"
            >
              Sélectionner une section
            </label>
            <select
              id="selectSection"
              className="w-full px-3 py-2 border rounded-lg focus:outline-none focus:ring focus:border-blue-300"
              value={selectedSectionId}
              onChange={(e) => setSelectedSectionId(e.target.value)}
              required
            >
              <option value="">Sélectionner une section</option>
              {sections.map((section) => (
                <option key={section._id} value={section._id}>
                  {section.title}
                </option>
              ))}
            </select>
          </div>
        )}

        {selectedSectionId && (
          <button
            onClick={handleDelete}
            className="w-full bg-red-500 text-white font-semibold py-2 rounded-lg hover:bg-red-600 transition duration-200"
          >
            Supprimer la section
          </button>
        )}
      </div>
    </div>
  );
};

export default DeleteSectionActivity;
