import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import axiosInstance from "../middleware/axiosInstance";
import FondBlanc from "../component/eventsDetail/FondBlanc";
import FondNoir from "../component/eventsDetail/FondNoir";
import ContactezNousNoir from "../component/ContacterNousNoir";
import parse from "html-react-parser";
import { Helmet } from "react-helmet";

function EventDetailScreen() {
  const { name } = useParams();
  const [event, setEvent] = useState(null);

  const getImageURL = (imageName) =>
    `${process.env.REACT_APP_URL}image/${imageName}`;

  useEffect(() => {
    axiosInstance
      .get(`events/name/${name}`)
      .then((response) => {
        setEvent(response.data);
      })
      .catch((error) => console.log(error.message));
  }, [name]);

  if (!event) {
    return <div>Loading...</div>;
  }

  return (
    <div>
      <Helmet>
        <title>{event.name} - Merveilleux Moments</title>
        <meta name="description" content={event.description} />
        <meta
          name="keywords"
          content={`événements, décoration, ${event.name}, ${event.keywords}`}
        />
        <meta property="og:title" content={event.name} />
        <meta property="og:description" content={event.description} />
        <meta property="og:image" content={getImageURL(event.img_bandeau)} />
        <meta
          property="og:url"
          content={`https://merveilleuxmoment.fr/events/${name}`}
        />
        <meta property="og:type" content="website" />
        <meta name="twitter:card" content="summary_large_image" />
        <meta name="twitter:title" content={event.name} />
        <meta name="twitter:description" content={event.description} />
        <meta name="twitter:image" content={getImageURL(event.img_bandeau)} />
      </Helmet>
      <div className="mb-16 bg-black"></div>
      <div className="text-center">
        <div className="bg-black">
          <h1 className="md:text-6xl text-4xl text-white my-8 Museo py-6">{event.name}</h1>
          <img
            src={getImageURL(event.img_bandeau)}
            alt={`${event.img_bandeau} banner`}
            className="w-full lg:h-[600px] h-[300px] object-cover text-white mb-16"
          />
        </div>
        <div className="flex items-center justify-center mb-10">
          <div className="h-1 w-52 bg-black"></div>
          <div className="px-4">
            <div className="text-center text-black MohaveBold md:text-5xl text-3xl">
              Découvrez nos offres
            </div>
          </div>
          <div className="h-1 w-52 bg-black"></div>
        </div>
      </div>
      <div>
        {event.section_id.length > 0 ? (
          event.section_id.map((section, index) => {
            if (index % 2 === 0) {
              return (
                <FondBlanc
                  key={section.id}
                  title={section.name}
                  imageSrc={getImageURL(section.image)}
                  texte={section.texte}
                />
              );
            } else {
              return (
                <FondNoir
                  key={section.id}
                  title={section.name}
                  imageSrc={getImageURL(section.image)}
                  texte={section.texte}
                />
              );
            }
          })
        ) : (
          <p>Loading...</p>
        )}
      </div>
      <div className="lg:w-[50vw] text-center mx-auto mt-24 w-[75vw] Museo md:text-4xl text-2xl">
        {parse(event.texte_frais)}
      </div>
      <div className="lg:w-[50vw] text-center mx-auto mt-24 w-[75vw] Museo md:text-4xl text-2xl mb-16">
        <u>
          Venez prendre rendez-vous pour définir ensemble votre thème et
          découvrir les planches tendances
        </u>
      </div>
      <div className="mb-16">
        <ContactezNousNoir />
      </div>
    </div>
  );
}

export default EventDetailScreen;
