import React from "react";
import parse from "html-react-parser";

function OffreMariage({ title, content, texte_frais, texte_rdv }) {
  return (
    <div className="w-[98%] sm:w-[70%] lg:w-1/2 p-4">
      <div className="sm:w-[80%] w-[95%] mx-auto">
        <div className="text-center text-black MuseoBold text-4xl mb-8">
          {title}
        </div>
        <div className="bg-gradient-to-b from-lightgreen  via-[#808113] to-green p-6 rounded-[50px] text-center">
          <div className="sm:w-[70%] w-[90%] mx-auto">
            <div className="MuseoBold text-xl mb-8">{parse(texte_rdv)}</div>
            <div className="text-white Museo text-xl mb-8">
              {parse(content)}
            </div>
            <div className="text-white Museo text-xl">{parse(texte_frais)}</div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default OffreMariage;
