import React from "react";
import { FaTiktok, FaInstagram } from "react-icons/fa";
import { Link } from "react-router-dom";

function Footer() {
  return (
    <footer className="bg-black text-white py-8 ">
      <div className="container mx-auto px-4">
        <img src="/logo.svg" alt="Logo du site" className="h-32 mx-auto" />

        <div className="flex flex-col lg:flex-row justify-between items-center">
          <div className="flex flex-col lg:flex-row items-center space-y-4 lg:space-y-0 lg:space-x-8">
            <Link to="/" className="hover:underline">
              Accueil
            </Link>
            <Link to="/evenement" className="hover:underline">
              Événements
            </Link>
            <Link to="/activite" className="hover:underline">
              Ateliers/cours
            </Link>
            <Link to="/location" className="hover:underline">
              Location
            </Link>
            <Link to="/contact" className="hover:underline">
              Contact
            </Link>
          </div>
          <div className="flex space-x-4 mt-6 lg:mt-0">
            <a
              href="https://www.tiktok.com/@merveilleuxmoment?"
              target="_blank"
              rel="noopener noreferrer"
              className="text-white text-2xl hover:text-lightgreen"
            >
              <FaTiktok />
            </a>
            <a
              href="https://www.instagram.com/merveilleuxmoment/"
              target="_blank"
              rel="noopener noreferrer"
              className="text-white text-2xl hover:text-lightgreen"
            >
              <FaInstagram />
            </a>
          </div>
        </div>
        <div className="text-center mt-8">
          <p>© 2024 Merveilleux Moment. All rights reserved.</p>
          <p className="mt-2">
            06 84 30 21 12 | Du lundi au samedi de 8h à 18h
          </p>
          <Link to="/mentions-legales" className="hover:underline">
            <div className="mt-10">Mentions légales</div>
          </Link>
        </div>
      </div>
    </footer>
  );
}

export default Footer;
