import React, { useState, useEffect } from "react";
import axiosInstance from "../../middleware/axiosInstance";

const EditLocation = () => {
  const [locations, setLocations] = useState([]);
  const [selectedLocationId, setSelectedLocationId] = useState("");
  const [locationData, setLocationData] = useState(null);
  const [name, setName] = useState("");

  // Fetch all locations on component mount
  useEffect(() => {
    axiosInstance
      .get("/location")
      .then((response) => {
        setLocations(response.data);
      })
      .catch((error) => {
        console.error("Error fetching locations:", error);
      });
  }, []);

  // Fetch selected location data when selectedLocationId changes
  useEffect(() => {
    if (selectedLocationId) {
      axiosInstance
        .get(`/location/${selectedLocationId}`)
        .then((response) => {
          const locationData = response.data;
          setLocationData(locationData);
          setName(locationData.name);
        })
        .catch((error) => {
          console.error("Error fetching location:", error);
        });
    }
  }, [selectedLocationId]);

  const handleSubmit = async (e) => {
    e.preventDefault();

    try {
      // Prepare location data
      const updatedLocationData = {
        name,
      };

      // Send location data to the server
      await axiosInstance.put(
        `/location/${selectedLocationId}`,
        updatedLocationData
      );
      alert("Location updated successfully!");
    } catch (error) {
      console.error("There was an error updating the location!", error);
    }
  };

  return (
    <div className="mt-32 flex justify-center">
      <form
        onSubmit={handleSubmit}
        className="bg-white p-8 rounded-lg shadow-lg w-full max-w-lg"
      >
        <h2 className="text-2xl font-bold mb-6">Modifier la Location</h2>

        <div className="mb-4">
          <label
            className="block text-gray-700 font-semibold mb-2"
            htmlFor="selectLocation"
          >
            Sélectionner une location à modifier
          </label>
          <select
            id="selectLocation"
            className="w-full px-3 py-2 border rounded-lg focus:outline-none focus:ring focus:border-blue-300"
            value={selectedLocationId}
            onChange={(e) => setSelectedLocationId(e.target.value)}
            required
          >
            <option value="">Sélectionner une location</option>
            {locations.map((location) => (
              <option key={location._id} value={location._id}>
                {location.name}
              </option>
            ))}
          </select>
        </div>

        {locationData && (
          <>
            <div className="mb-4">
              <label
                className="block text-gray-700 font-semibold mb-2"
                htmlFor="name"
              >
                Nom de la location
              </label>
              <input
                type="text"
                id="name"
                className="w-full px-3 py-2 border rounded-lg focus:outline-none focus:ring focus:border-blue-300"
                value={name}
                onChange={(e) => setName(e.target.value)}
                required
              />
            </div>

            <button
              type="submit"
              className="w-full bg-blue-500 text-white font-semibold py-2 rounded-lg hover:bg-blue-600 transition duration-200"
            >
              Modifier la Location
            </button>
          </>
        )}
      </form>
    </div>
  );
};

export default EditLocation;
