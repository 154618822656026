import React, { useState, useEffect, useRef, useCallback } from "react";
import axiosInstance from "../../middleware/axiosInstance";
import { Editor } from "@tinymce/tinymce-react";

const EditSectionLocation = () => {
  const [locations, setLocations] = useState([]);
  const [sections, setSections] = useState([]);
  const [selectedLocationId, setSelectedLocationId] = useState("");
  const [selectedSectionId, setSelectedSectionId] = useState("");
  const [sectionData, setSectionData] = useState(null);
  const [sectionTitle, setSectionTitle] = useState("");
  const [sectionImage, setSectionImage] = useState(null);
  const [sectionImageUrl, setSectionImageUrl] = useState("");
  const [sectionTexte, setSectionTexte] = useState("");
  const editorRef = useRef(null);

  useEffect(() => {
    const fetchLocations = async () => {
      try {
        const response = await axiosInstance.get("/location");
        setLocations(response.data);
      } catch (error) {
        console.error("There was an error fetching the locations!", error);
      }
    };

    fetchLocations();
  }, []);

  const handleLocationChange = (event) => {
    setSelectedLocationId(event.target.value);
    setSelectedSectionId("");
    setSectionData(null);
    if (event.target.value) {
      const selectedLocation = locations.find(
        (l) => l._id === event.target.value
      );
      setSections(selectedLocation.sectionLocation_id);
    } else {
      setSections([]);
    }
  };

  const handleSectionChange = (event) => {
    setSelectedSectionId(event.target.value);
    const section = sections.find(
      (section) => section._id === event.target.value
    );
    if (section) {
      setSectionData(section);
      setSectionTitle(section.title);
      setSectionImageUrl(section.img);
      setSectionTexte(section.texte);
    }
  };

  const handleFileChange = (event) => {
    const file = event.target.files[0];
    setSectionImage(file);
    setSectionImageUrl(file.name);
  };

  const onEditorChange = useCallback((content) => {
    setSectionTexte(content);
  }, []);

  const handleSubmit = async (e) => {
    e.preventDefault();

    const uploadFile = async (file) => {
      const formData = new FormData();
      formData.append("file", file);

      const config = {
        headers: { "content-type": "multipart/form-data" },
      };

      try {
        const response = await axiosInstance.post("/upload", formData, config);
        return response.data.filePath;
      } catch (error) {
        console.error("There was an error uploading the file!", error);
        throw error;
      }
    };

    let uploadedImageUrl = sectionImageUrl;
    if (sectionImage) {
      uploadedImageUrl = await uploadFile(sectionImage);
    }

    try {
      const updatedSectionData = {
        title: sectionTitle,
        img: uploadedImageUrl,
        texte: sectionTexte,
      };

      await axiosInstance.put(
        `/sectionsLocation/${selectedSectionId}`,
        updatedSectionData
      );
      alert("Section updated successfully!");
    } catch (error) {
      console.error("There was an error updating the section!", error);
    }
  };

  return (
    <div className="mt-32 flex justify-center">
      <form
        onSubmit={handleSubmit}
        className="bg-white p-8 rounded-lg shadow-lg w-full max-w-lg"
      >
        <h2 className="text-2xl font-bold mb-6">Modifier une Section</h2>
        <div className="mb-4">
          <label
            className="block text-gray-700 font-semibold mb-2"
            htmlFor="location"
          >
            Sélectionner une location
          </label>
          <select
            id="location"
            className="w-full px-3 py-2 border rounded-lg focus:outline-none focus:ring focus:border-blue-300"
            value={selectedLocationId}
            onChange={handleLocationChange}
            required
          >
            <option value="">Sélectionner une location</option>
            {locations.map((location) => (
              <option key={location._id} value={location._id}>
                {location.name}
              </option>
            ))}
          </select>
        </div>
        {selectedLocationId && (
          <div className="mb-4">
            <label
              className="block text-gray-700 font-semibold mb-2"
              htmlFor="section"
            >
              Sélectionner une section
            </label>
            <select
              id="section"
              className="w-full px-3 py-2 border rounded-lg focus:outline-none focus:ring focus:border-blue-300"
              value={selectedSectionId}
              onChange={handleSectionChange}
              required
            >
              <option value="">Sélectionner une section</option>
              {sections.map((section) => (
                <option key={section._id} value={section._id}>
                  {section.title}
                </option>
              ))}
            </select>
          </div>
        )}
        {sectionData && (
          <>
            <div className="mb-4">
              <label
                className="block text-gray-700 font-semibold mb-2"
                htmlFor="sectionTitle"
              >
                Titre de la section
              </label>
              <input
                type="text"
                id="sectionTitle"
                className="w-full px-3 py-2 border rounded-lg focus:outline-none focus:ring focus:border-blue-300"
                value={sectionTitle}
                onChange={(e) => setSectionTitle(e.target.value)}
                required
              />
            </div>
            <div className="mb-4">
              <label
                className="block text-gray-700 font-semibold mb-2"
                htmlFor="sectionImage"
              >
                Image de la section
              </label>
              <input
                type="file"
                id="sectionImage"
                className="w-full px-3 py-2 border rounded-lg focus:outline-none focus:ring focus:border-blue-300"
                onChange={handleFileChange}
                encType="multipart/form-data"
              />
            </div>
            <div className="mb-4">
              <label className="block text-gray-700 font-semibold mb-2">
                Texte
              </label>
              <Editor
                apiKey="qechaaksdccwyq9o7krr71livdg5etn52i5krhlle0oszh9h"
                onInit={(_evt, editor) => (editorRef.current = editor)}
                value={sectionTexte}
                onEditorChange={onEditorChange}
                init={{
                  height: 500,
                  menubar: false,
                  plugins: [
                    "advlist",
                    "autolink",
                    "lists",
                    "link",
                    "image",
                    "charmap",
                    "preview",
                    "anchor",
                    "searchreplace",
                    "visualblocks",
                    "code",
                    "fullscreen",
                    "insertdatetime",
                    "media",
                    "table",
                    "code",
                    "help",
                    "wordcount",
                  ],
                  toolbar:
                    "undo redo | formatselect | bold italic underline | alignleft aligncenter alignright alignjustify | bullist numlist outdent indent | removeformat | help",
                }}
              />
            </div>
            <button
              type="submit"
              className="w-full bg-blue-500 text-white font-semibold py-2 rounded-lg hover:bg-blue-600 transition duration-200"
            >
              Modifier la Section
            </button>
          </>
        )}
      </form>
    </div>
  );
};

export default EditSectionLocation;
