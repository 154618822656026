import React, { useState, useEffect } from "react";
import axiosInstance from "../middleware/axiosInstance";
import parse from "html-react-parser";
import { Helmet } from "react-helmet";

function ContactScreen() {
  const [events, setEvents] = useState([]);
  const [location, setLocation] = useState([]);
  const [atelier, setAtelier] = useState([]);
  const [contact, setContact] = useState({});
  const [formData, setFormData] = useState({
    name: "",
    phone: "",
    email: "",
    eventType: "",
    date: "",
    message: "",
  });
  const [formStatus, setFormStatus] = useState("");

  useEffect(() => {
    axiosInstance
      .get("events")
      .then((response) => {
        setEvents(response.data);
      })
      .catch((error) => {
        console.error("Error fetching events:", error);
      });

    axiosInstance
      .get("location")
      .then((response) => {
        setLocation(response.data);
      })
      .catch((error) => {
        console.error("Error fetching events:", error);
      });

    axiosInstance
      .get("atelier")
      .then((response) => {
        setAtelier(response.data);
      })
      .catch((error) => {
        console.error("Error fetching events:", error);
      });

    axiosInstance
      .get("contacts")
      .then((response) => {
        setContact(response.data[0]);
      })
      .catch((error) => {
        console.error("Error fetching contacts:", error);
      });
  }, []);

  const handleChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    axiosInstance
      .post("mail", formData)
      .then((response) => {
        setFormStatus("Email envoyé avec succès!");
        setFormData({
          name: "",
          phone: "",
          email: "",
          eventType: "",
          date: "",
          message: "",
        });
      })
      .catch((error) => {
        setFormStatus("Erreur lors de l'envoi de l'email.");
        console.error("Error sending email:", error);
      });
  };

  return (
    <div className="bg-black text-white min-h-screen Museo mt-16">
      <Helmet>
        <title>Contactez-nous - Merveilleux Moments</title>
        <meta
          name="description"
          content="Contactez Merveilleux Moments pour organiser des événements inoubliables (Anniversaire, mariage, événement en tout genre)."
        />
        <meta
          name="keywords"
          content="contact, événements, décoration, mariage, anniversaire, Val-de-Marne, Maryline, Merveilleux Moments"
        />
        <meta
          property="og:title"
          content="Contactez-nous - Merveilleux Moments"
        />
        <meta
          property="og:description"
          content="Contactez Merveilleux Moments pour organiser des événements inoubliables."
        />
        <meta
          property="og:image"
          content="https://votre-site.com/images/og-contact-image.jpg"
        />
        <meta
          property="og:url"
          content="https://merveilleuxmoment.fr/contact"
        />
        <meta property="og:type" content="website" />
      </Helmet>
      <div className="flex flex-col items-center mt-8">
        <img src="logo.svg" alt="Logo" className="w-96 h-96 mb-8" />

        <div className="flex flex-col lg:flex-row w-full px-8 lg:px-32 mb-16">
          <div className="flex-1 flex flex-col items-center mb-8 lg:mb-0">
            <h2 className="text-3xl font-bold mb-12 text-center">
              {contact.ZI}
            </h2>
            <p className="text-lg text-center mb-12 w-[80%]">{contact.text1}</p>
            <div className="w-[80%] h-[430px]">
              <iframe
                width="100%"
                height="100%"
                className="rounded-[30px]"
                src="https://maps.google.com/maps?hl=en&amp;q=%2023%20Rue%20de%20Paris,%2094340%20Joinville-le-Pont+(My%20Business%20Name)&amp;t=&amp;z=11&amp;ie=UTF8&amp;iwloc=B&amp;output=embed"
              ></iframe>
            </div>
          </div>

          <div className="flex-1 flex flex-col items-center">
            <h2 className="text-3xl font-bold mb-4 text-center max-w-lg">
              {contact.text2}
            </h2>
            <p className="text-lg text-center mb-12 max-w-lg">
              {contact.text3 && typeof contact.text3 === "string"
                ? parse(contact.text3)
                : null}
            </p>
            <form className="w-full max-w-lg" onSubmit={handleSubmit}>
              <div className="flex flex-wrap -mx-3 mb-6">
                <div className="w-full md:w-1/2 px-3 mb-6 md:mb-0">
                  <input
                    name="name"
                    value={formData.name}
                    onChange={handleChange}
                    className="appearance-none block w-full bg-[#D9D9D9] text-black rounded-[20px] py-3 px-4 leading-tight focus:outline-none focus:bg-white"
                    type="text"
                    placeholder="Nom-Prénom"
                  />
                </div>
                <div className="w-full md:w-1/2 px-3">
                  <input
                    name="phone"
                    value={formData.phone}
                    onChange={handleChange}
                    className="appearance-none block w-full bg-[#D9D9D9] text-black rounded-[20px] py-3 px-4 leading-tight focus:outline-none focus:bg-white"
                    type="text"
                    placeholder="Téléphone"
                  />
                </div>
              </div>
              <div className="flex flex-wrap -mx-3 mb-6">
                <div className="w-full px-3">
                  <input
                    name="email"
                    value={formData.email}
                    onChange={handleChange}
                    className="appearance-none block w-full bg-[#D9D9D9] text-black rounded-[20px] py-3 px-4 leading-tight focus:outline-none focus:bg-white"
                    type="email"
                    placeholder="Email"
                  />
                </div>
              </div>
              <div className="flex flex-wrap -mx-3 mb-6">
                <div className="w-full md:w-1/2 px-3 mb-6 md:mb-0">
                  <select
                    name="eventType"
                    value={formData.eventType}
                    onChange={handleChange}
                    className="appearance-none block w-full bg-[#D9D9D9] text-black rounded-[20px] py-3 px-4 leading-tight focus:outline-none focus:bg-white"
                  >
                    <option value="" disabled>
                      Type d'événement
                    </option>
                    {events.map((event) => (
                      <option key={event._id} value={event.name}>
                        {event.name}
                      </option>
                    ))}
                    {location.map((location) => (
                      <option key={location._id} value={location.name}>
                        {location.name}
                      </option>
                    ))}
                    {atelier.map((atelier) => (
                      <option key={atelier._id} value={atelier.name}>
                        {atelier.name}
                      </option>
                    ))}
                  </select>
                </div>
                <div className="w-full md:w-1/2 px-3">
                  <input
                    name="date"
                    value={formData.date}
                    onChange={handleChange}
                    className="appearance-none block w-full bg-[#D9D9D9] text-black rounded-[20px] py-3 px-4 leading-tight focus:outline-none focus:bg-white"
                    type="date"
                  />
                </div>
              </div>
              <div className="flex flex-wrap -mx-3 mb-6">
                <div className="w-full px-3">
                  <textarea
                    name="message"
                    value={formData.message}
                    onChange={handleChange}
                    className="appearance-none block w-full bg-[#D9D9D9] text-black rounded-[20px] py-3 px-4 leading-tight focus:outline-none focus:bg-white"
                    placeholder="Message"
                    rows="4"
                  ></textarea>
                </div>
              </div>
              <div className="flex flex-wrap -mx-3 mb-6">
                <div className="w-full px-3 flex justify-center">
                  <button
                    type="submit"
                    className="mt-4 text-3xl Museo px-10 py-2 border bg-green border-green rounded-2xl mx-auto text-white hover:bg-lightgreen hover:text-black"
                  >
                    Envoyer
                  </button>
                </div>
              </div>
              <p className="text-center text-sm">
                Nous sommes là pour transformer votre événement en un instant
                magique
              </p>
              {formStatus && (
                <p className="text-center text-lg mt-4">{formStatus}</p>
              )}
            </form>
          </div>
        </div>

        <div className="text-center mb-8">
          <h3 className="text-xl font-bold">
            {contact.maryline && typeof contact.maryline === "string"
              ? parse(contact.maryline)
              : null}
          </h3>
        </div>

        <div className="text-center mb-16">
          <div className="text-lg">
            {contact.disponibilite && typeof contact.disponibilite === "string"
              ? parse(contact.disponibilite)
              : null}
          </div>
        </div>
      </div>
    </div>
  );
}

export default ContactScreen;
