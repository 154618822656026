import React, { useState, useEffect, useRef } from "react";
import { FaTiktok, FaInstagram, FaBars, FaTimes } from "react-icons/fa";
import { Link, useNavigate } from "react-router-dom";
import axiosInstance from "../middleware/axiosInstance";

function Navbar() {
  const [isEventDropdownOpen, setIsEventDropdownOpen] = useState(false);
  const [isActivityDropdownOpen, setIsActivityDropdownOpen] = useState(false);
  const [isLocationDropdownOpen, setIsLocationDropdownOpen] = useState(false);
  const [isMobileMenuOpen, setIsMobileMenuOpen] = useState(false);
  const [events, setEvents] = useState([]);
  const [locations, setLocations] = useState([]);
  const [atelier, setAtelier] = useState([]);
  const eventTimeoutRef = useRef(null);
  const activityTimeoutRef = useRef(null);
  const locationTimeoutRef = useRef(null);
  const navigate = useNavigate();

  useEffect(() => {
    // Fetch events, ateliers, and locations from the API
    axiosInstance
      .get("/events")
      .then((response) => {
        setEvents(response.data);
      })
      .catch((error) => console.log(error.message));

    axiosInstance
      .get("/atelier")
      .then((response) => {
        setAtelier(response.data);
      })
      .catch((error) => console.log(error.message));

    axiosInstance
      .get("/location")
      .then((response) => {
        setLocations(response.data);
      })
      .catch((error) => console.log(error.message));
  }, []);

  const handleEventMouseEnter = () => {
    if (eventTimeoutRef.current) {
      clearTimeout(eventTimeoutRef.current);
      eventTimeoutRef.current = null;
    }
    setIsEventDropdownOpen(true);
  };

  const handleEventMouseLeave = () => {
    eventTimeoutRef.current = setTimeout(() => {
      setIsEventDropdownOpen(false);
    }, 300);
  };

  const handleActivityMouseEnter = () => {
    if (activityTimeoutRef.current) {
      clearTimeout(activityTimeoutRef.current);
      activityTimeoutRef.current = null;
    }
    setIsActivityDropdownOpen(true);
  };

  const handleActivityMouseLeave = () => {
    activityTimeoutRef.current = setTimeout(() => {
      setIsActivityDropdownOpen(false);
    }, 300);
  };

  const handleLocationMouseEnter = () => {
    if (locationTimeoutRef.current) {
      clearTimeout(locationTimeoutRef.current);
      locationTimeoutRef.current = null;
    }
    setIsLocationDropdownOpen(true);
  };

  const handleLocationMouseLeave = () => {
    locationTimeoutRef.current = setTimeout(() => {
      setIsLocationDropdownOpen(false);
    }, 300);
  };

  const handleEventClick = (event) => {
    if (event.name === "Mariage") {
      navigate("/mariage");
    } else {
      navigate(`/event/${event.name}`);
    }
  };

  const handleLocationClick = (location) => {
    navigate(`/location/${location.name}`);
  };

  const handleAtelierClick = (Atelier) => {
    navigate(`/Activite/${Atelier.name}`);
  };

  const handleButtonClick = () => {
    navigate("/evenement");
  };
  const handleLocClick = () => {
    navigate("/location");
  };
  const handleAteClick = () => {
    navigate("/activite");
  };

  const onclickImage = () => {
    navigate("/");
  };

  const toggleMobileMenu = () => {
    setIsMobileMenuOpen(!isMobileMenuOpen);
  };

  return (
    <>
      <nav className="bg-black p-4 flex items-center justify-between fixed top-0 w-full z-10">
        <img
          src="/logo.svg"
          alt="Logo du site"
          className="h-8 cursor-pointer"
          onClick={onclickImage}
        />

        <div className="hidden lg:flex absolute left-1/2 transform -translate-x-1/2 space-x-8">
          <Link
            to="/"
            className="text-white bg-transparent border border-lightgreen py-2 px-4"
          >
            Accueil
          </Link>
          <div
            className="relative"
            onMouseEnter={handleEventMouseEnter}
            onMouseLeave={handleEventMouseLeave}
          >
            <button
              className="text-white bg-transparent border border-lightgreen py-2 px-4"
              onClick={handleButtonClick}
            >
              Événements
            </button>
            {isEventDropdownOpen && (
              <div
                className="absolute left-0 mt-2 w-48 bg-white shadow-lg rounded-lg"
                onMouseEnter={handleEventMouseEnter}
                onMouseLeave={handleEventMouseLeave}
              >
                {events.map((event) => (
                  <div
                    key={event._id}
                    onClick={() => handleEventClick(event)}
                    className="block px-4 py-2 text-black hover:bg-lightgreen hover:text-white cursor-pointer"
                  >
                    {event.name}
                  </div>
                ))}
              </div>
            )}
          </div>
          <div
            className="relative"
            onMouseEnter={handleActivityMouseEnter}
            onMouseLeave={handleActivityMouseLeave}
          >
            <button
              className="text-white bg-transparent border border-lightgreen py-2 px-4"
              onClick={handleAteClick}
            >
              Ateliers/cours
            </button>
            {isActivityDropdownOpen && (
              <div
                className="absolute left-0 mt-2 w-48 bg-white shadow-lg rounded-lg"
                onMouseEnter={handleActivityMouseEnter}
                onMouseLeave={handleActivityMouseLeave}
              >
                {atelier.map((atelier) => (
                  <div
                    key={atelier._id}
                    onClick={() => handleAtelierClick(atelier)}
                    className="block px-4 py-2 text-black hover:bg-lightgreen hover:text-white cursor-pointer"
                  >
                    {atelier.name}
                  </div>
                ))}
              </div>
            )}
          </div>
          <div
            className="relative"
            onMouseEnter={handleLocationMouseEnter}
            onMouseLeave={handleLocationMouseLeave}
          >
            <button
              className="text-white bg-transparent border border-lightgreen py-2 px-4"
              onClick={handleLocClick}
            >
              Location
            </button>
            {isLocationDropdownOpen && (
              <div
                className="absolute left-0 mt-2 w-48 bg-white shadow-lg rounded-lg"
                onMouseEnter={handleLocationMouseEnter}
                onMouseLeave={handleLocationMouseLeave}
              >
                {locations.map((location) => (
                  <div
                    key={location._id}
                    onClick={() => handleLocationClick(location)}
                    className="block px-4 py-2 text-black hover:bg-lightgreen hover:text-white cursor-pointer"
                  >
                    {location.name}
                  </div>
                ))}
              </div>
            )}
          </div>
        </div>

        <div className="flex items-center space-x-4">
          <a
            href="https://www.tiktok.com/@merveilleuxmoment?"
            target="_blank"
            rel="noopener noreferrer"
            className="text-white text-2xl"
          >
            <FaTiktok />
          </a>
          <a
            href="https://www.instagram.com/merveilleuxmoment/"
            target="_blank"
            rel="noopener noreferrer"
            className="text-white text-2xl"
          >
            <FaInstagram />
          </a>
          <button
            className="text-white bg-transparent border border-lightgreen py-2 px-4 rounded"
            onClick={() => navigate("/contact")}
          >
            Nous contacter
          </button>
          <button
            className="text-white text-2xl lg:hidden"
            onClick={toggleMobileMenu}
          >
            {isMobileMenuOpen ? <FaTimes /> : <FaBars />}
          </button>
        </div>
      </nav>

      {isMobileMenuOpen && (
        <div className="lg:hidden fixed top-24 xsm:top-16 right-0 w-1/3 z-50 bg-black flex flex-col items-start space-y-4 py-4">
          <Link
            to="/"
            className="text-white bg-transparent py-2 px-4 w-full text-left"
            onClick={toggleMobileMenu}
          >
            Accueil
          </Link>
          <button
            className="text-white bg-transparent py-2 px-4 w-full text-left"
            onClick={() => {
              handleButtonClick();
              toggleMobileMenu();
            }}
          >
            Événements
          </button>
          <button
            className="text-white bg-transparent py-2 px-4 w-full text-left"
            onClick={() => {
              handleAteClick();
              toggleMobileMenu();
            }}
          >
            Ateliers/cours
          </button>
          <button
            className="text-white bg-transparent py-2 px-4 w-full text-left"
            onClick={() => {
              handleLocClick();
              toggleMobileMenu();
            }}
          >
            Location
          </button>
        </div>
      )}
    </>
  );
}

export default Navbar;
