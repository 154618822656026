import React from "react";
import { useNavigate } from "react-router-dom";
import parse from "html-react-parser";

function FondBlancOffre({ idEvent, title, imageSrc, description }) {
  const navigate = useNavigate();

  const handleLearnMoreClick = () => {
    if (title === "Mariage") {
      navigate("/mariage");
    } else {
      navigate(`/event/${title}`);
    }
  };

  return (
    <div className="bg-white py-16">
      <h2 className="md:text-6xl text-4xl text-center mx-auto text-black mb-16 Museo w-[90%]">
        {title}
      </h2>
      <div className="container mx-auto flex flex-col lg:flex-row">
        <div className="lg:w-1/2 flex justify-center items-center">
          <img
            src={imageSrc}
            alt="Description de l'image"
            className="rounded-[90px] h-[400px] object-cover"
          />
        </div>
        <div className="lg:w-1/2 Museo flex text-center flex-col justify-center items-start p-8 text-black">
          <div className="mb-4 md:text-2xl text-xl">{parse(description)}</div>
          <button
            className="mt-10 text-2xl Museo px-6 py-2 border bg-green border-green rounded-2xl mx-auto text-white hover:bg-lightgreen hover:text-black"
            onClick={handleLearnMoreClick}
          >
            En savoir plus
          </button>
        </div>
      </div>
    </div>
  );
}

export default FondBlancOffre;
